export enum RequestStatus {
	None 			= 0,
	Ok 				= 200,
	Created 		= 201,
	Success 		= 204,
	Partial 		= 206,
	Invalid 		= 400,
	Unauthorized 	= 401,
	Forbidden 		= 403,
	NotFound 		= 404,
	ServerError 	= 500,
	Unavailable 	= 503,
}

export enum Sort{
	ASC,
	DESC,
}

export enum GenderType {
  FEMENINE    = 'Femenino',
  MASCULINE   = 'Masculino',
}
export enum PreguntaType{
  ABIERTO   = 'Abierta',
  MULTIPLE  = 'Multiple',
  UNICA     = 'Unica'
}
export enum ActionType {
  CREATE  = 'crear',
  EDIT    = 'edit',
}

export enum PaymentStatusType {
  PAID      = 'Pagado',
  UNPAID    = 'Sin Pagar',
}
export enum ValidForm {
  VALID     = 'Valid',
  INVALID   = 'Invalid',
}
export enum ImportationType {
  PESOS     = 'Pesos',
  DOLARES   = 'Dolares',
}
export enum StatusType {
  PENDING           = 'Pendiente',
  PENDING_APPROVAL  = 'Pendiente Aprobación',
  APPROVED          = 'Aprobado',
}
export enum TareaServicioStatus{
  PENDIENTE_SERVICIOS_A_CLIENTES = 27,
  COMPLETADO_SERVICIOS_A_CLIENTES     = 28,
  CERRADO = 17
}

export enum RoleType {
  SUPER_ADMIN = '1',
  ADMIN = '2',
  DIRECTOR = '3' ,
  GERENTE = '4' ,
  COORDINADOR = '5',
  STAFF = '6'
}

export enum InputType {
  SELECT_DROPDOWN     = 'select_dropdown',
  SELECT_MULTIPLE     = 'select_multiple',
  SELECT_SEARCH       = 'select_search',
  TEXT                = 'text',
  PASSWORD            = 'password',
  NUMBER              = 'number',
  RELATIONSHIP        = 'relationship',
  DATE                = 'date',
  IMAGE               = 'image',
  COLOR               = 'color',
  CHECKBOX            = 'checkbox',
  TIMESTAMP           = 'timestamp',
  DATE_MONTH          = 'date-month',
  TIME                = 'time'

}
