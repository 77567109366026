<ng-multiselect-dropdown id="dropdown-list"
	[placeholder]="placeholder"
	[settings]="settings"
	[data]="data"
	[(ngModel)]="selecteds"
	(onSelect)="changeData()"
	(onDeSelect)="changeData()"
	(onSelectAll)="return_all()"
	(onDeSelectAll)="return_none()"
>
</ng-multiselect-dropdown>

