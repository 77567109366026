<app-principal-menu></app-principal-menu>
<app-header></app-header>

<div style="position: absolute;left: 50%;z-index: 99999" *ngIf="!datas">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
</div>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">

    <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
        <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <!--begin::Details-->
          <div class="d-flex align-items-center flex-wrap mr-2">
            <!--begin::Title-->
            <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5" *ngIf="datas">
              Gandores de {{ datas.loteria.nombre }} ({{ datas.sorteo.nombre }})
            </h5>
            <!--begin::Separator-->
            <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
            <!--end::Separator-->
            <!--begin::Search Form-->
            <div class="d-flex align-items-center" id="kt_subheader_search">
              <span class="text-dark-50 font-weight-bold" id="kt_subheader_total" *ngIf="datas">{{datas.ganadores.length}} Total</span>
            </div>

            <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>

            <div class="resultados" *ngIf="datas">    
                <ng-container *ngFor="let resultado of datas.resultados; let i = index">
                  <span>{{ resultado.numero }}</span>
                </ng-container>
            </div>
          </div>
        </div>
    </div>

    <div class="card-body" >

        <table datatable [dtOptions]="dtOptions" class="table row-border hover" *ngIf="ganadores">
            <thead class="bg-gray-100" *ngIf="ganadores">
            <tr>
                <th>Cliente</th>
                <th>Tipo juego</th>
                <th>Numeros ganadores</th>
                <th>Monto apostado</th>
                <th>Monto a pagar</th>
                <th>Estado</th>
                <th>Acciones
                    <a href="javascript:;" style="margin-left: 5px;float:right" class="btn btn-sm btn-light-danger btn-icon"
                    data-toggle="modal" data-target="#removeAll" title="Rechazar jugada">
                        <i class="la la-remove"></i>
                    </a>

                    <a href="javascript:;" style="float:right" class="btn btn-sm btn-light-success btn-icon" 
                    data-toggle="modal" data-target="#allowAll" title="Aprobar jugada">
                        <i class="la la-check"></i>
                    </a>
                </th>
            </tr>
            </thead>
            <tbody *ngIf="ganadores">
            <tr *ngFor="let data of ganadores">
                <td>{{ data.jugada.order.cliente.nombres }}</td>
                <td>{{ data.jugada.tipo_juego.nombre }}</td>
                <td *ngIf="data.numeros_posiciones">
                    <ng-container *ngFor="let numero of data?.numeros_posiciones; let i = index;">
                        {{ numero?.numero }} ({{ numero?.nombre_posicion }}) <span *ngIf="data?.numeros_posiciones.length != i+1"> - </span>
                    </ng-container>
                </td>
                <td>{{ data.total_apostado | currency:"RD$"}}</td>
                <td>{{ data.total_ganado | currency:"RD$"}} <span *ngIf="data.total_ganado >= data.limit"><i class="la la-warning text-danger bg-light-danger p-2 rounded animated flash"></i></span></td>
                <td>
                    <div [ngSwitch]="data.estado">
                        <div *ngSwitchCase="2" class="bg-danger text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Rechazada</div>
                        <div *ngSwitchCase="1" class="bg-success text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Aprobada</div>
                        <div *ngSwitchCase="0" class="bg-warning text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Pendiente</div>
                    </div>
                </td>
            <td style="text-align: center;" class="acciones">
                <a href="javascript:;" class="btn btn-sm btn-light-primary btn-icon" data-toggle="modal" data-target="#view" (click)="setApproveData(data.jugada.order)" title="Ver jugada">
                    <i class="la la-eye"></i>
                </a>
                <a href="javascript:;" class="btn btn-sm btn-light-success btn-icon" title="Aprobar jugada" [ngClass]="{'disabled': data.estado}">
                    <i class="la la-check" (click)="changeStatus(data.id, 1)"></i>
                </a>
                <a href="javascript:;" class="btn btn-sm btn-light-danger btn-icon" title="Rechazar jugada" (click)="changeStatus(data.id, 2)" [ngClass]="{'disabled': data.estado==2}">
                    <i class="la la-remove"></i>
                </a>
            </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="allowAll" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">¿Está seguro que deseas aceptar todas las jugadas de este resultado?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <label>Vuelve a escribir lo números ganadores</label>
          <div class="row justify-content-center p-4">
              <div class="col-3">
                <label>1era</label>
                <input type="number" class="form-control" [min]="1" [max]="100" [(ngModel)]="v_resultados[0]">
              </div>
              <div class="col-3">
                <label>2da</label>
                <input type="number" class="form-control" [min]="1" [max]="100" [(ngModel)]="v_resultados[1]">
              </div>
              <div class="col-3">
                <label>3era</label>
                <input type="number" class="form-control" [min]="1" [max]="100" [(ngModel)]="v_resultados[2]">
              </div>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="changeAllStatus(1)" class="btn btn-success font-weight-bold" data-dismiss="modal">Aceptar todo</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeAll" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header btn-danger">
          <h5 class="modal-title" style="color:white;">¿Está seguro que deseas rechazar todas las jugadas ganadoras de este registro resultado?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light font-weight-bold" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="changeAllStatus(0)" class="btn btn-danger font-weight-bold" data-dismiss="modal">Rechazar todo</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="view"  tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ticket #{{ approveData?.id }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="approveData?.details">
                <ng-container *ngFor="let detail of approveData?.details">
                    <h5>{{ detail?.sorteo.nombre }} - {{ (detail?.super_pale)?'Super Palé':detail?.tipo_juego.nombre }}  <kbd>{{ detail?.monto | currency:"RD$"}}</kbd></h5>

                    <span>
                        {{ detail?.numeros }}
                    </span>
                </ng-container>
                
            </ng-container>
            <h6>Total: {{ approveData?.monto | currency:"RD$"}}</h6>
        </div>
      </div>
    </div>
  </div>

<app-footer></app-footer>