<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea un nuevo ' + modulo : 'Edita un ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_login_signin_form" autocomplete="off">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-6">
          <label>Nombre del tipo de juego:</label>
          <input [(ngModel)]="tipo_juego.nombre" type="text" name="nombres" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del del tipo de juego"/>
          <span class="form-text text-muted">Por favor, Introduzca el nombre del tipo de juego.</span>
        </div>
        <div class="col-lg-6">
          <label>Cantidad de jugadas:</label>
          <input [(ngModel)]="tipo_juego.cantidad_numeros" type="number" name="cantidad_jugadas" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Cantidad de jugadas"/>
          <span class="form-text text-muted">Por favor, Introduzca la cantidad de jugadas permitidas al usuario.</span>
        </div>
        <div class="col-lg-6">
            <label>Posiciones permitidas:</label>
            <app-select [data]="posicionesData" [multiple]="true" *ngIf="!loading" text="nombre" (value)="posiciones=$event" [selecteds]="selecteds"></app-select>
            <span class="form-text text-muted">Por favor, Introduzca la cantidad de jugadas permitidas al usuario.</span>
          </div>
        <div class="col-lg-6">
          <label>Estado:</label>
          <div class="radio-inline">
            <label class="radio radio-solid">
              <input [(ngModel)]="tipo_juego.estado" type="radio" name="estado"  value="Activo"/>
              <span></span>
              Activo
            </label>
            <label class="radio radio-solid">
              <input [(ngModel)]="tipo_juego.estado" type="radio" name="estado" value="Inactivo"/>
              <span></span>
              Inactivo
            </label>
          </div>
          <span class="form-text text-muted">Por defecto el estado viene activo, pero lo puede cambiar!</span>
        </div>


        <div class="col-lg-3" style="margin: auto;">
          <button (click)="generarCombinaciones();" [disabled]="loadingSave" class="btn btn-danger font-weight-bold btn-sm px-4 font-size-base ml-2">
              <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
              <span *ngIf="!loadingSave">
                <i class="la la-refresh"></i>
                Generar combinaciones
              </span>
            </button>
        </div>

      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-12" style="margin: auto;" *ngIf="combinaciones_exist">
              <table class="table row-border hover" *ngFor="let combinacion of combinaciones">
                <thead class="bg-gray-100" *ngIf="combinacion.combinaciones">
                <tr>
                  <th>Combinaciones</th>
                  <th>Acciones
                    <a href="javascript:;"
                      style="margin-left:5px; float: right;"
                      data-toggle="modal" data-target="#addCombination"
                      class="btn btn-sm btn-success btn-icon" title="Agregar combinación">
                      <i class="la la-plus"></i>
                    </a>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of combinacion.combinaciones; let x = index">
                  <td>
                    <span class="posicion" *ngFor="let item of data ; let i = index">
                      <kbd>{{ item.nombre }}</kbd> <span *ngIf="data.length != i+1"> - </span>
                    </span>
                  </td>
                  <td style="text-align: center;" >
                    <a href="javascript:;" *ngIf="(currentMenu && currentMenu.edit) || req.loggedUser.role_id === 1" (click)="edit(x)" class="btn btn-sm btn-light-warning btn-icon" title="Editar">
                      <i class="la la-edit"></i>
                    </a>
          
                    <a href="javascript:;"
                       style="margin-left:5px;" (click)="deleteCombination(x)"
                       data-toggle="modal" data-target="#deleteRecord"
                       *ngIf="(currentMenu && currentMenu.remove) || req.loggedUser.role_id === 1"
                       class="btn btn-sm btn-light-danger btn-icon" title="Eliminar">
                      <i class="la la-remove"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
        </div>

        <!-- Modals -->

        <div class="modal fade" id="addCombination" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Agregar combinación a {{ tipo_juego.nombre }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body">
                <form class="form" autocomplete="off">
                  <div class="col-lg-12">
                    <label>Seleccionar posiciones:</label>
                    <app-select [data]="posicionesData" [multiple]="true" *ngIf="!loading" text="nombre" (value)="nuevas_posiciones=$event"></app-select>
                    <span class="form-text text-muted">Seleccionar la posiciones a combinar.</span>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-success font-weight-bold" data-dismiss="modal" (click)="agregarCombinacion()">Agregar</button>
              </div>
            </div>
          </div>
        </div>

        <!-- End Modals -->
        <div class="col-lg-3" style="margin: auto;">
          <button (click)="guardar();" [disabled]="loadingSave" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>

          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-footer></app-footer>