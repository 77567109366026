import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Nav} from "../services/nav.service";
import {RequestService} from "../services/request.service";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PrincipalMenuComponent } from './components/principal-menu/principal-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {DataTablesModule} from "angular-datatables";
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { UsuariosAddOrEditComponent } from './pages/usuarios-add-or-edit/usuarios-add-or-edit.component';
import { SearchModuleFormComponent } from './components/search-module-form/search-module-form.component';
import { SearchComponent } from './pages/search/search.component';
import { AddOrEditComponent } from './pages/add-or-edit/add-or-edit.component';
import { AddOrEditFormComponent } from './components/add-or-edit-form/add-or-edit-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { SelectEmpresaComponent } from './pages/select-empresa/select-empresa.component';
import {GoogleMapsModule} from '@angular/google-maps';
import { ManageCSVComponent } from './components/manage-csv/manage-csv.component';
import { SelectComponent } from './components/select/select.component';
import { TipoJuegoComponent } from './pages/tipo-juego/tipo-juego.component';
import { TipoJuegoAddOrEditComponent } from './pages/tipo-juego-add-or-edit/tipo-juego-add-or-edit.component';
import {TabComponent} from './components/tabs/tab.component';
import {TabsComponent} from './components/tabs/tabs.component';
import { LoteriaComponent } from './pages/loteria/loteria.component';
import { LoteriaAddOrEditComponent } from './pages/loteria-add-or-edit/loteria-add-or-edit.component';
import { ResultadosComponent } from './pages/resultados/resultados.component';
import { ResultadosAddOrEditComponent } from './pages/resultados-add-or-edit/resultados-add-or-edit.component'
import { CarterasComponent } from './pages/carteras/carteras.component';
import { CarterasAddOrEditComponent } from './pages/carteras-add-or-edit/carteras-add-or-edit.component';
import { JugadasComponent } from './pages/jugadas/jugadas.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { ResultadosGanadoresComponent } from './pages/resultados-ganadores/resultados-ganadores.component';
import { SolicitudRetirosComponent } from './pages/solicitud-retiros/solicitud-retiros.component';
import { ClientsComponents } from './pages/clients/clients.component';
import { ClientsAddOrEditComponent } from './pages/clients-add-or-edit/clients-add-or-edit.component';
import { LimitesComponents } from './pages/limites/limites.component';
import { LimitessAddOrEditComponent } from './pages/limites-add-or-edit/limites-add-or-edit.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		DashboardComponent,
		PrincipalMenuComponent,
		HeaderComponent,
		FooterComponent,
		UsuariosComponent,
		UsuariosAddOrEditComponent,
		SearchModuleFormComponent,
		SearchComponent,
		AddOrEditComponent,
		AddOrEditFormComponent,
		EditProfileComponent,
		SelectEmpresaComponent,
		ManageCSVComponent,
		SelectComponent,
		TipoJuegoComponent,
		TipoJuegoAddOrEditComponent,
		TabsComponent,
    	TabComponent,
    	LoteriaComponent,
    	LoteriaAddOrEditComponent,
    	ResultadosComponent,
		ResultadosAddOrEditComponent,
		CarterasComponent,
		CarterasAddOrEditComponent,
		JugadasComponent,
		SelectSearchComponent,
		ResultadosGanadoresComponent,
		SolicitudRetirosComponent,
		ClientsComponents,
		ClientsAddOrEditComponent,
		LimitesComponents,
		LimitessAddOrEditComponent
	],
	imports: [
		SweetAlert2Module.forRoot(),
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		DataTablesModule,
		ReactiveFormsModule,
		NgbModule,
    	NgMultiSelectDropDownModule.forRoot(),
		NgxDaterangepickerMd.forRoot(),
		GoogleMapsModule,
	],
	providers: [Nav, RequestService],
	bootstrap: [AppComponent]
})
export class AppModule { }
