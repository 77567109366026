<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea un nuevo ' + modulo : 'Edita un ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_login_signin_form" autocomplete="off" *ngIf="!loading">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-6">
          <label>Tipo:</label>
          <select name="tipo" [(ngModel)]="limite.tipo" class="form-control form-control-solid">
            <option value="-1">Selecciona el tipo de limitación</option>
            <option value="0">Monto por jugada</option>
            <option value="1">Combinación</option>
          </select>
          <span class="form-text text-muted">Selecciona el tipo de limitación</span>
        </div>

        <div class="col-lg-6">
          <label>Nombre:</label>
          <input [(ngModel)]="limite.nombre" type="text" name="nombre" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombre"/>
        </div>

        <div class="col-lg-6" *ngIf="limite.tipo == 0">
          <label>Valor:</label>
          <input [(ngModel)]="limite.valor" type="text" name="valor" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Valor"/>
        </div>

        <div class="col-lg-6">
          <label>Loteria:</label>
          <select name="loteria" [(ngModel)]="limite.loteria_id" [value]="0" class="form-control form-control-solid" (change)="loadSorteos()">
              <option value="0">Selecciona la loteria</option>
              <option [value]="loteria.id" *ngFor="let loteria of loteriasData">{{ loteria.nombre }}</option>
          </select>
          <span class="form-text text-muted">Selecciona la loteria</span>
        </div>

        <div class="col-lg-6">
          <label>Sorteos:</label>
          <app-select [data]="sorteosData" [multiple]="true" *ngIf="!loading" text="nombre" (value)="sorteos=$event" [selecteds]="selecteds"></app-select>
          <span class="form-text text-muted">Por favor, Introduzca los tipos de juegos de esta loteria.</span>
        </div>

        <div class="col-lg-6">
          <label>Tipo juego:</label>
          <select name="tipo_juego" [(ngModel)]="limite.tipo_juego_id" [value]="0" class="form-control form-control-solid" (change)="loadSorteos()">
              <option value="0">Selecciona el sorteo</option>
              <option [value]="tipo.id" *ngFor="let tipo of tipos_juego">{{ tipo.nombre }}</option>
          </select>
          <span class="form-text text-muted">Selecciona la loteria</span>
        </div>

        <div class="col-lg-6">
          <label>Fecha de inicio:</label>
          <input [(ngModel)]="limite.start_date" type="date" name="start_date" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Fecha de inicio"/>
        </div>

        <div class="col-lg-6">
          <label>Fecha de cierre:</label>
          <input [(ngModel)]="limite.end_date" type="date" name="end_date" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Fecha de cierre"/>
        </div>

        <div class="col-lg-6">
          <label>Estado:</label>
          <div class="radio-inline">
            <label class="radio radio-solid">
              <input [(ngModel)]="limite.estado" type="radio" name="estado"  value="Activo"/>
              <span></span>
              Activo
            </label>
            <label class="radio radio-solid">
              <input [(ngModel)]="limite.estado" type="radio" name="estado" value="Inactivo"/>
              <span></span>
              Inactivo
            </label>
          </div>
          <span class="form-text text-muted">Por defecto el estado viene activo, pero lo puede cambiar!</span>
        </div>



      </div>
    </div>
    <div class="card-footer">
      <div class="row">

        <div class="col-12" *ngIf="limite.loteria_id && limite.tipo == 1">
          <table class="table row-border hover">
            <thead>
            <tr>
              <th>Tipo de juego</th>
              <th>Valores</th>
              <th>
                <button class="btn btn-dark float-right" data-toggle="modal" data-target="#add"><i class="las la-plus"></i> Agregar</button>
              </th>
            </tr>
            </thead>
            <tbody *ngIf="limites_select">
              <tr *ngFor="let data of limites_select; let x = index">
                <td>{{data.tipo_juego.nombre}}</td>
                <td>
                  <div class="container row">
                  <ng-container *ngFor="let item of data.valores; let i = index">
                    {{ item }} <span *ngIf="data?.valores.length != i+1"> - </span>
                  </ng-container>
                 </div>
                </td>
                <td>
                  <ng-container [ngSwitch]="data.tipo_limite">
                    <span *ngSwitchCase="1">Cántidad de jugadas: {{ data.limite_valor | number }}</span>
                    <span *ngSwitchCase="2">Monto máximo a apostar por jugada: {{ data.limite_valor | currency:"RD$"}}</span>
                    <span *ngSwitchCase="3">Monto máximo a jugar por todos los usuarios: {{ data.limite_valor | currency:"RD$" }}</span>
                  </ng-container>

                  <a class="btn btn-danger float-right" (click)="removeLimite(x)"><i class="las la-trash"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-lg-3" style="margin: auto;">
          <button (click)="guardar();" [disabled]="loadingSave" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>

          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="modal" id="add" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agregar nueva combinación</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <label>Tipo de juego</label>
          <select name="select_tipo_juego" [(ngModel)]="select_tipo_juego" class="form-control" (change)="selectTipoJuego()">
            <option value="0">Selecciona el tipo de juego</option>
            <option data-id="{{ data.id }}" value="{{ data.id }}" *ngFor="let data of tipos_juego">{{ data.nombre }}</option>
          </select>

          <div class="row justify-content-center p-4">
              <ng-container *ngFor="let item of tipoJuegoData.cantidad_numeros; let i = index">
                <div class="col-3">
                  <label>{{ tipoJuegoData.posiciones[i].nombre }}</label>
                  <input type="number" class="form-control" [min]="1" [max]="100" [(ngModel)]="limites[i]">
                </div>
              </ng-container>
          </div>

          <label>Tipo de limite</label>
          <select name="tipo_limite" [(ngModel)]="tipo_limite" class="form-control">
            <option value="0">Seleccione el tipo de limitación</option>
            <option value="1">Cántidad de jugadas</option>
            <option value="2">Monto máximo a apostar por jugada</option>
            <option value="3">Monto máximo a jugar por todos los usuarios</option>
          </select>
          <div class="py-4">
            <input type="number" name="limite" [(ngModel)]="limite_valor" class="form-control" placeholder="Valor del limite">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-success font-weight-bold" data-dismiss="modal" (click)="saveLimites()">Agregar</button>
        </div>
      </div>
    </div>
  </div>

</div>
<app-footer></app-footer>
