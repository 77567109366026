import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RequestService} from "./request.service";
import {Location} from '@angular/common';
// @ts-ignore
@Injectable()
export class Nav {
  data: any;

  constructor(private router: Router, private req : RequestService, private _location: Location) {
    if(!this.req.loggedUser.id){
      this.push('login');
    }

    // ...
  }
  back() {
    this._location.back();
  }
  push(url: any, data: any = '') {
    this.data = data;
    this.router.navigate([url], {queryParams: data});
  }

  get(key: string) {
    return this.data ? this.data[key] : null;
  }
}
