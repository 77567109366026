import { Component, OnInit } from '@angular/core';
import {Module} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment.prod";

@Component({
  selector: 'app-add-or-edit',
  templateUrl: './add-or-edit.component.html',
  styleUrls: ['./add-or-edit.component.css']
})
export class AddOrEditComponent implements OnInit {
  module:Module;
  id:string;
  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    this.actRoute.queryParamMap.subscribe(async params => {
      const module    = params.get('module');
      if(module) {
        this.module = await this.req.GET('module?module=' + module);
        if(!this.module){
          this.nav.push(environment.error_page);
        }
      }else{
        this.nav.push(environment.error_page);
      }

      this.id = params.get('id');

    });
  }

}
