
export class Usuario {
  id: number;
  nombres: string;
  email: string;
  apellidos: string;
  departamento:string;
  telefono: string;
  extension: string;
  genero:string;
  flota: string;
  created_at: string;
  contrasena:string;
  avatar:any;
  estado:string;
  usuario:string;
  role_id:any;
  reset_password: number;
  empresa: any;
  empresas:any;
  empresa_selected: any;
  supervisor_id: any;
}
export class Gasto{
  id: number;
  gasto: number;
  monto: number;
  importacion_id: number;
  tipo: string;
  pagado: string;
  concepto: string;
  tasa: number;
  created_at: any;
  empresa_id:number;
}

export class ImportacionDocumento{
  id: number;
  descripcion: number;
  documento: any;
  importacion_id: number;
  empresa_id:number;
}
export class Producto {
  id: number;
  nombre: string;
  precio: number;
  itbis: number;
  cantidad: number;
  costo_us: number;
  costo_rd: number;
  total_itbis: number;
  costo_itbis: number;
  dist: number;
  gastos_import: number;
  costo_total: number;
  costo_unit: number;
  precio_unit: number;
  beneficio_total: number;
  estado:string;
  hash:string;
}

export class Pregunta{
  id: number;
  title: string;
  type: string;
  order: number;
  encuesta: number;
  obligatory: string;
  comment: string;
  opciones: any;
  hash: string;
}
export class Opciones{
  id: number;
  title: string;
  pregunta: number;
}
export class Departamento {
  id: number;
  nombre: string;
  encargado: string;
  estado: string;
  created_at: string;
}
export class Categoria {
  id:number;
  nombre:string;
  estado:string;
  cliente:any;
  subtarea:any;
  table: string;
}
export class TareaServicio {
  id: number;
  nombre: string;
  estado: string;
  usuario_id: number;
  nombre_usuario: string;
  fecha_entrega:string;
  created_at: string;
  cliente_id:number;
  selected: any;
  tarea: Tarea;
}
export class Calendarizar {
  id: number;
  cliente: number;
  servicio: number;
  usuario: number;
  usuario_asignado:number;
  estado: string;
  periodo: string;
  created_at: string;
  tareas_servicios: ClienteServicio[];
}
export class ClienteServicio {
  id: string;
  cliente_id: string;
  servicio_id: string;
  nombre_servicio: string;
  nombre_cliente: string;
  cedula_pasaporte: string;
  nombre_usuario_responsable: string;
  created_at: string;
  usuario_id: string;
  tareas_servicios: TareaServicio[];
}
export class Servicio {
  id: string;
  nombre: string;
  departamento: string;
  estado: string;
  created_at: string;
}
export class PosicionesEmpleados {
  id: number;
  nombre: string;
  estado: string;
  created_at: string;
}
export class Module {
  id: number;
  name: string;
  display_name_singular: string;
  display_name_plural: string;
}
export interface Field {
  id:number;
  data_type_id?: number;
  field: string;
  type: string;
  display_name?: string;
  required?: string[];
  browse?: number;
  read?: any;
  edit?: any;
  add?: any;
  delete?: any;
  details?: any;
  order: number;
  value:any;
  options?:any;

}
export interface ModulePermissions {
  table_name: string;
  permissions: Permission[];
}

export interface Permission {
  id: number;
  key: string;
  label: string;
  value: boolean;
}
export interface Menu {
  id: number;
  title: string;
  url?: string;
  childrens?: Children[];
  active?:boolean;
  opened?: boolean;
  browse?: boolean;
  read?: boolean;
  add?: boolean;
  edit?: boolean;
  remove?: boolean;
}

export interface Children {
  id: number;
  title: string;
  url: string;
  active?:boolean;
  browse?: boolean;
  read?: boolean;
  add?: boolean;
  edit?: boolean;
  remove?: boolean;
}
export class SubTarea {
  id: number;
  nombre: string;
  completado:number;
  tarea_id:number;
  active:boolean;
  ramdom_number:number;
  fecha_limite: string;
  fecha_limite_str: string;
  usuario_asignado_id: number;
  foto_usuario_asignado:string;
  nombre_usuario_asignado:string;
  created_at:string;
  created_by: number;
}
export class Tarea {
  id: number;
  titulo: string;
  descripcion: string;
  fecha_limite: string;
  fecha_limite_str:string;
  usuario_asignado_id: number;
  usuario_afectado_id: number;
  is_draft:boolean;
  estado_id: number;
  prioridad_id: string;
  prioridad:string;
  usuario_solicitante_id: number;
  categoria_id: string;
  created_at: string;
  created_at_str:string;
  nombre_usuario_asignado:string;
  nombre_usuario_afectado:string;
  nombre_usuario_solicitante:string;
  foto_usuario_afectado:string;
  foto_usuario_asignado:string;
  foto_usuario_solicitante:string;
  estado: string;
  categoria: string;
  tipo: string;
  tipo_id: string;
  color_prioridad:string;
  documentos: Documento[];
  subtareas: SubTarea[];
  comentarios: TareaComentario[];
  usuario_solicitante:Usuario;
  cliente_id:number;
  nombre_cliente:string;
  foto_cliente:string;
  hasCliente:number;
  hasSubTarea:number;
  numero_caso:number;
  marcada:number;
  update_type:string;
  current_user_id:number;
  razon_tardanza_entrega_id:number;
  razon_tardanza_entrega:string;
}

export class Documento{
  id:number;
  name:string;
  url:string;
  size:string;
  created_at:string;
  complete:number;
  tipo:string;
}
export class TareaComentario{
  id:number;
  usuario_id:number;
  usuario_nombre:string;
  usuario_foto:string;
  tarea_id:number;
  comentario:string;
  created_at:string;
  created_at_str:string;
  quill:any;
  edited:number;
  editar:boolean;
  loading: boolean;
  tipo:any;
}
export class TareaActualizacion{
  id:number;
  usuario_id:number;
  usuario_nombre:string;
  usuario_foto:string;
  tarea_id:number;
  tipo:string;
  created_at:string;
}
export class Cliente {
  id: number;
  nombres: string;
  cedula_pasaporte: string;
  telefono?: string;
  direccion?: string;
  email?: any;
  estado?: any;
  avatar:any;
  nombre_banco?:string;
  cuenta_banco?:string;
  created_at?: any;
  updated_at?: any;
  deleted_at?: any;
  contrasena:any;
  puntos:any;
  cartera: any;
  jugadas:any;
  table:string;
}
export class Estado {
  id: number;
  nombre: string;
  estado: string;
  created_at?: string;
}
export class EstadoCategoria {
  id: number;
  estado_id:number;
  categoria_id: number;
  nombre: string;
  estado: string;
  created_at?: string;
}

export class TipoJuego{
  id: number;
  nombre: String;
  cantidad_numeros: number;
  estado: string;
  logo:string;
  combinaciones_valor:[
    {combinacion_id:number,
    valor:number}
  ]
}

export class Loteria{
  id:number;
  nombre:string;
  logo:string;
  estado:string;
}

export class Combinacion{
  id:number;
  cantidad_jugada:number;
  tipo_juego_id:number;
  posicion_juego:number;
}

export class LoteriaCombinacion{
  id:number;
  loteria_id: number;
  combinacion_id:number;
  valor: number;
}

export class Resultado{
  id:number;
  sorteo_id:number;
}

export class Cartera{
  id:number;
  cliente_id:number;
  trx:string;
  monto:number;
  descripcion:string;
  status:number;
  user_id:number;
  tipo:number;
  change_amount:boolean;
}

export class Limite{
  id:number;
  tipo:number;
  valor:string;
  start_date:string;
  end_date:string;
  estado:string;
  user_id:string;
  created_at:string;
  nombre:string;
  loteria_id:number;
  sorteos:any;
  details:any;
  tipo_juego_id:number;
}