import { Component, Input, OnInit } from '@angular/core';
import {RequestService} from "../../../services/request.service";
import Swal from "sweetalert2";
import {Nav} from "../../../services/nav.service";
import {environment} from "../../../environments/environment.prod";

@Component({
  selector: 'app-manage-csv',
  templateUrl: './manage-csv.component.html',
  styleUrls: ['./manage-csv.component.css']
})
export class ManageCSVComponent implements OnInit {

  @Input() table;
  @Input() moduleName;
  @Input() canImport = true;
  @Input() canDownload = true;
  url = environment.url;
  mostrar: boolean;
  csv = null;
  fecha_inicial: any;
  fecha_final:any;
  constructor(public req: RequestService, public nav: Nav) {
    this.mostrar = true;
  }

  ngOnInit(): void {
    this.fecha_inicial      = this.req.getDefaultDate(0);
    this.fecha_final        = this.req.getDefaultDate(1);
  }

  changeCSV(ev)
  {
    if(ev.target.files.length > 0){
      this.csv = ev.target.files[0];
    } else {
      this.csv = null;
    }
  }

  async importarPlantilla()
  {
    if(this.csv == null) return;
    const url = "import_csv";
    let formData = new FormData();
    formData.append('file', this.csv);
    formData.append('t', this.table);
    formData.append('e', this.req.loggedUser.empresa_selected.id);
    formData.append('user', (this.req.loggedUser.id).toString());
    
    const response    = await this.req.POST_FILE(url, formData);

    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      return;
    }else {
      Swal.fire(
          '',
          '<p>'+response.response+'</p>',
          'success'
      );
      window.location.reload();
    }
  }

}
