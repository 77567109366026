import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {Categoria, Cliente, Documento, Field, Menu, Module} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {NgForm} from "@angular/forms";
import {Nav} from "../../../services/nav.service";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {InputType} from "../../../enums/enums";
import {environment} from "../../../environments/environment.prod";
import Swal from "sweetalert2";
import {HttpEventType} from "@angular/common/http";
import * as moment from "moment";
import { ActivatedRoute, Router } from '@angular/router';
declare var toastr;
declare var KTImageInput;
@Component({
  selector: 'app-carteras',
  templateUrl: './carteras.component.html',
  styleUrls: ['./carteras.component.css']
})
export class CarterasComponent implements OnInit {
  
  module_id;
  moduleName;
  table;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger= new Subject();
  fields: Field[];
  datas:any;
  InputType = InputType;
  cliente: Cliente = new Cliente();
  categoria:Categoria = new Categoria();
  currentMenu: Menu;
  complete_load_clientes: number = 0;
  file_load_clientes:any;
  importCSV = false;
  downloadCSV = false; 
  module:Module;
  dt
  constructor(public req: RequestService, private nav: Nav, private router: Router, private actRoute: ActivatedRoute) {

  }
async ngOnInit() {
  this.module_id = 107
  this.moduleName = "Cartera";
  this.table = 'carteras';
 
  this.importCSV = (this.table !== 'empresas');
  this.downloadCSV = (this.table !== 'empresas' && this.table != 'encuestas');

  if(this.module_id != null) {
    this.fields = await this.req.GET('module_fields?module=' + this.module_id);
    for (let field of this.fields){
      if (field.type === InputType.SELECT_DROPDOWN) {
        field.details.options = Object.keys(field.details.options).map(function (key) {
          console.log(key)
          return field.details.options [key];
        });
      }else if(field.type === InputType.RELATIONSHIP){
        field.options = await this.req.GET_URL(environment.relation_url + this.table + '/relation?type=' + field.field);
      }
    }
  }

  this.dtOptions = {
    pagingType: 'full_numbers',
    searching: false,
    lengthChange: false,
    order: []
  };

  const response= await this.req.GET('all_carteras');
  
  if(response.responseCode == "success"){
    this.datas = response.response;
  }
  
  this.dtTrigger.next();
}

buscar(form: NgForm){
  this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
    this.datas = null;
    dtInstance.destroy();
    form.form.value.table = this.table;
    form.form.value.empresa = this.req.loggedUser.empresa_selected.id;
    this.datas = await this.req.POST('data', form.form.value);
    this.dtTrigger.next();
  });
}
edit(id) {
  this.nav.push('tipo-juego/add-or-edit', {module: this.table, id: id});
}
ngOnDestroy(): void {
  this.dtTrigger.unsubscribe();
}
create() {
  this.nav.push('carteras/add-or-edit', {module: this.table});
}
async refrescar(){
  window.location.reload();
}

loadingSave:boolean;
async guardar(){
  if (!this.cliente.nombres || !this.cliente.cedula_pasaporte){
    Swal.fire(
      '',
      'Todos los campos son obligatorios',
      'error'
    );
    return;
  }
  this.cliente.table = 'clientes';
  this.loadingSave  = true;
  const response    = await this.req.POST('save',this.cliente);
  this.loadingSave  = false;
  if (response.responseCode === 'error') {
    Swal.fire(
      '',
      response.message,
      'error'
    );
    return;
  }else{
    $("#cancelarCrearServicio").click();
    this.nav.push("/clientes/add-or-edit", {module: this.table, id: response.response.id});
  }
}

}
