import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Cliente, Menu, Usuario} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponents implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  datas:any;
  dtTrigger= new Subject();
  modulo = "clientes";
  cliente: Cliente[];
  currentMenu: Menu;
  search:Cliente = new Cliente();
  constructor(public req: RequestService, private ngZone: NgZone, private nav: Nav, private router: Router) { }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  async ngOnInit(){
    this.search.estado    = '';
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      lengthChange: false
    };

    const response = await this.req.GET('all_clientes');
    if(response.responseCode == "success"){
      this.datas = response.response;
    }
    this.dtTrigger.next();
  }

  edit(id: number) {
    this.nav.push("clients/add-or-edit",{id});
  }

  buscar(){
    this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
      this.datas = null;
      dtInstance.destroy();
      this.datas = await this.req.GET('clientes?'+'nombres=' + this.search.nombres + "&telefono=" + this.search.telefono + "&estado=" + this.search.estado);
      this.dtTrigger.next();
    });
  }

  formatDate(date){
    return this.req.formatDate(date, 'LL')
  }
 
}
