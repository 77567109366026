import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Cliente, Menu, Usuario} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {Router} from "@angular/router";
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-limites',
  templateUrl: './limites.component.html',
  styleUrls: ['./limites.component.css']
})
export class LimitesComponents implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  datas:any;
  dtTrigger= new Subject();
  modulo = "limites";
  currentMenu: Menu;
  constructor(public req: RequestService, private ngZone: NgZone, private nav: Nav, private router: Router) { }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  async ngOnInit(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      lengthChange: false
    };

    const response = await this.req.GET('all_limites');
    if(response.responseCode == "success"){
      this.datas = response.response;
    }
    this.dtTrigger.next();
  }

  edit(id: number) {
    this.nav.push("limites/add-or-edit",{id});
  }

  create() {
    this.nav.push("limites/add-or-edit");
  }

  dismissData:any;

  setDismissData(data){
    this.dismissData = data;
  }

  async deleteRecord(id){
    const response = await this.req.POST('delete_limite', {id});
    if(response.responseCode == 'success'){
      Swal.fire(
        '',
        'Registro eliminado correctamente',
        'success'
      );

    }

    this.nav.push('limites');
  }


  formatDate(date){
    var validate = moment(date).isValid();

    if(!validate) return 'Not Date';

    return this.req.formatDate(date, 'LL')
  }
 
}
