<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea un nuevo ' + modulo : 'Edita un ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
      <button *ngIf="accion !== 'crear'" data-toggle="modal" data-target="#resetPass"
              style="float:right;"
              [disabled]="loadingResetPass" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
        <img src="assets/loading-white.gif" width="20" *ngIf="loadingResetPass">
        <span *ngIf="!loadingResetPass">
              <i class="flaticon2-refresh-1"></i>
              Reiniciar Contraseña
            </span>
      </button>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_login_signin_form" autocomplete="off">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-12">
          <div class="image-input" id="kt_image_2" style="margin: auto;width: 120px;display: block;margin-bottom: 20px;">
            <div class="image-input-wrapper" style="background-image: url({{this.departamento.avatar || img_default}})"></div>

            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
              <i class="fa fa-pen icon-sm text-muted"></i>
              <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"/>
              <input type="hidden" name="profile_avatar_remove"/>
            </label>

            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-6" *ngIf="!single_empresa">
          <label >Empresa:</label>
          <select class="form-control form-control-solid selectpicker"
                  name="empresa"
                  multiple [(ngModel)]="departamento.empresa" (change)="changeRole()">
            <option *ngFor="let empresa of empresas" [value]="empresa.id">{{empresa.nombre}}</option>
          </select>
          <span class="form-text text-muted">Por favor, Seleccione una o mas empresas</span>
        </div>
        <div class="col-lg-6">
          <label>Rol:</label>
          <select class="form-control" name="role_id" [(ngModel)]="departamento.role_id" (change)="changeRole()">
            <option value="" >Seleccione</option>
            <option [value]="data.id" *ngFor="let data of roles">{{data.name}}</option>
          </select>
          <span class="form-text text-muted">Qué es rol tendrá este usuario?</span>
        </div>
        <div class="col-lg-6">
          <label>Nombres:</label>
          <input [(ngModel)]="departamento.nombres" type="text" name="nombres" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del usuario"/>
          <span class="form-text text-muted">Por favor, Introduzca los nombres del usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Apellidos:</label>
          <input [(ngModel)]="departamento.apellidos" type="text" name="apellidos" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Apellidos del usuario"/>
          <span class="form-text text-muted">Por favor, Introduzca los apellidos del usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Email:</label>
          <input (change)="validateExist('email',departamento.email)" [(ngModel)]="departamento.email"
                 autocomplete="off" type="text" name="email" class="form-control form-control-solid" placeholder="Email del usuario"/>
          <span class="form-text text-muted">Por favor, Introduzca el email del usuario</span>
        </div>
        <div class="col-lg-6">
          <label>Telefono:</label>
          <input [(ngModel)]="departamento.telefono" type="text" name="telefono" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Telefono del usuario"/>
          <span class="form-text text-muted">Numero de telefono del usuario</span>
        </div>

        <div class="col-lg-6">
          <label>Género:</label>
          <div class="radio-inline">
            <label class="radio radio-solid">
              <input [(ngModel)]="departamento.genero" type="radio" name="genero" (change)="handleChange()" value="Masculino"/>
              <span></span>
              Masculino
            </label>
            <label class="radio radio-solid">
              <input [(ngModel)]="departamento.genero" type="radio" name="genero" (change)="handleChange()" value="Femenino"/>
              <span></span>
              Femenino
            </label>
          </div>
          <span class="form-text text-muted">Qué género es el usuario?</span>
        </div>
        <div class="col-lg-6">
          <label>Estado:</label>
          <div class="radio-inline">
            <label class="radio radio-solid">
              <input [(ngModel)]="departamento.estado" type="radio" name="estado"  value="Activo"/>
              <span></span>
              Activo
            </label>
            <label class="radio radio-solid">
              <input [(ngModel)]="departamento.estado" type="radio" name="estado" value="Inactivo"/>
              <span></span>
              Inactivo
            </label>
          </div>
          <span class="form-text text-muted">Por defecto el estado viene activo, pero lo puede cambiar!</span>
        </div>



      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-3" style="margin: auto;">
          <button (click)="guardar();" [disabled]="loadingSave" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>

          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal fade" id="resetPass" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header btn-danger">
        <h5 class="modal-title" style="color:white;">Está seguro que desea reiniciar la contraseña?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="resetPassword()" class="btn btn-success font-weight-bold" data-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
