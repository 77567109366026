/**
 * The main component that renders single TabComponent
 * instances.
 */

 import {
    Component,
    ContentChildren,
    QueryList,
    AfterContentInit,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef
  } from "@angular/core";
  
  import { TabComponent } from "./tab.component";
  
  @Component({
    selector: "my-tabs",
    template: `
      <ul class="nav nav-tabs">
        <li
          *ngFor="let tab of tabs"
          (click)="selectTab(tab)"
          [class.active]="tab.active"
        >
          <a href="javascript:void(0)">{{ tab.title }}</a>
        </li>
      </ul>
      <ng-content></ng-content>
    `,
    styles: [
      `
        .tab-close {
          color: gray;
          text-align: right;
          cursor: pointer;
        }

        ul li a{
            margin:20px;
        }

        li.active{
          background-color: #f6b031!important;
          border-color: #f6b031!important;
          transition: 0.4s;
        }

        li.active a{
          color: #FFFFFF!important;
          transition: 0.4s;
        }
      `
    ]
  })
  export class TabsComponent implements AfterContentInit {
    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  
    // contentChildren are set
    ngAfterContentInit() {
      // get all active tabs
      let activeTabs = this.tabs.filter(tab => tab.active);
  
      // if there is no active tab set, activate the first
      if (activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }
    }
  
    selectTab(tab) {
      // deactivate all tabs
      this.tabs.toArray().forEach(tab => (tab.active = false));
  
      // activate the tab the user has clicked on.
      tab.active = true;
    }
  }
  