<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          Módulo de {{modulo}}
        </h5>

        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <!--end::Separator-->
        <!--begin::Search Form-->
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total" *ngIf="datas">{{datas.length}} Total</span>
        </div>
      </div>
      <div class="d-flex align-items-center" *ngIf="(currentMenu && currentMenu.add)">
        <!--end::Button-->
        <!--begin::Button-->
        <a href="usuarios/add-or-edit?module=usuarios"
           class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
          <i class="la la-plus"></i>
          Agregar {{modulo}}
        </a>
      </div>
    </div>

  </div>
  <div class="card-body">
    <div style="position: absolute;left: 48%;z-index: 99999" *ngIf="!datas">
      <img src="assets/loading.gif" style="width:100px;height:auto">
      <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
    </div>
    <div class="mb-7">
      <div class="row align-items-center">
        <div class="col-lg-12 col-xl-12">
          <div class="row align-items-center">
            <div class="col-md-3 my-2 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Nombres:</label>
                <input [(ngModel)]="search.nombres" class="form-control" name="nombres" placeholder="Nombre del usuario">
              </div>
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Apellidos:</label>
                <input [(ngModel)]="search.apellidos" class="form-control" name="apellidos" placeholder="Apellidos del usuario">
              </div>
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Email:</label>
                <input [(ngModel)]="search.email" class="form-control" name="email" placeholder="Email del usuario">
              </div>
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Estado:</label>
                <select [(ngModel)]="search.estado" name="estado" class="form-control" id="kt_datatable_search_status">
                  <option value="">Todos</option>
                  <option value="Activo">Activo</option>
                  <option value="Inactivo">Inactivo</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-12 mt-5 mt-lg-0" style="margin-top:10px!important;">
          <a style="margin: auto;width: 112px;display: block;" href="javascript:void(0)"
             class="btn btn-light-primary px-6 font-weight-bold" (click)="buscar()">
            <i class="la la-search"></i>
            Búscar
          </a>
        </div>
      </div>
    </div>
    <!--begin: Datatable-->
    <table datatable [dtOptions]="dtOptions" class="row-border hover" [dtTrigger]="dtTrigger">
      <thead *ngIf="datas">
      <tr>
        <th>ID</th>
        <th>Nombres</th>
        <th>Apellidos</th>
        <th>Email</th>
        <th>Estado</th>
        <th>Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of datas">
        <td>{{data.id}}</td>
        <td>{{data.nombres}}</td>
        <td>{{data.apellidos}}</td>
        <td>{{data.email}}</td>
        <td>{{data.estado}}</td>
        <td>
          <a href="javascript:void(0);"
             *ngIf="(currentMenu && currentMenu.edit) || req.loggedUser.role_id === 1"
             (click)="edit(data.id)" class="btn btn-sm btn-clean btn-icon" title="Editar">
            <i class="la la-edit"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <!--end: Datatable-->
  </div>
</div>
<app-footer></app-footer>
