<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea un nuevo ' + modulo : 'Edita un ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_login_signin_form" autocomplete="off">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-12">
          <div class="image-input" id="kt_image_2" style="margin: auto;width: 120px;display: block;margin-bottom: 20px;">
            <div class="image-input-wrapper" style="background-image: url({{this.cliente.avatar || img_default}})"></div>

            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
              <i class="fa fa-pen icon-sm text-muted"></i>
              <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"/>
              <input type="hidden" name="profile_avatar_remove"/>
            </label>

            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-6">
          <label>Nombre completo:</label>
          <input [(ngModel)]="cliente.nombres" type="text" name="nombres" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del cliente"/>
          <span class="form-text text-muted">Por favor, Introduzca los nombres del cliente</span>
        </div>
        <div class="col-lg-6">
          <label>Email:</label>
          <input (change)="validateExist('email',cliente.email)" [(ngModel)]="cliente.email"
                 autocomplete="off" type="text" name="email" class="form-control form-control-solid" placeholder="Email del cliente"/>
          <span class="form-text text-muted">Por favor, Introduzca el email del cliente</span>
        </div>
        <div class="col-lg-6">
          <label>Telefono:</label>
          <input [(ngModel)]="cliente.telefono" type="text" name="telefono" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Telefono del cliente"/>
          <span class="form-text text-muted">Numero de telefono del cliente</span>
        </div>

        <div class="col-lg-6">
          <label>Cédula / Pasaporte:</label>
          <input [(ngModel)]="cliente.cedula_pasaporte" type="text" name="cedula_pasaporte" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del cliente" disabled/>
          <span class="form-text text-muted">Por favor, Introduzca los nombres del cliente</span>
        </div>

        <div class="col-lg-6">
          <label>Dirección:</label>
          <input [(ngModel)]="cliente.direccion" type="text" name="direccion" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Dirección del cliente" disabled/>
          <span class="form-text text-muted">Por favor, Introduzca los nombres del cliente</span>
        </div>

        <div class="col-lg-6">
          <label>Nombre banco:</label>
          <input [(ngModel)]="cliente.nombre_banco" type="text" name="nombre_banco" class="form-control form-control-solid"
                 autocomplete="off" placeholder="Nombres del banco del cliente" disabled/>
          <span class="form-text text-muted">Por favor, Introduzca los nombres del cliente</span>
        </div>

        <div class="col-lg-6">
          <label>No. Cuenta de banco:</label>
          <input [(ngModel)]="cliente.cuenta_banco" type="text" name="cuenta_banco" class="form-control form-control-solid"
                 autocomplete="off" placeholder="No. cuenta de banco del cliente" disabled/>
          <span class="form-text text-muted">Por favor, Introduzca los nombres del cliente</span>
        </div>

        <div class="col-lg-6">
          <label>Estado:</label>
          <div class="radio-inline">
            <label class="radio radio-solid">
              <input [(ngModel)]="cliente.estado" type="radio" name="estado"  value="Activo"/>
              <span></span>
              Activo
            </label>
            <label class="radio radio-solid">
              <input [(ngModel)]="cliente.estado" type="radio" name="estado" value="Inactivo"/>
              <span></span>
              Inactivo
            </label>
          </div>
          <span class="form-text text-muted">Por defecto el estado viene activo, pero lo puede cambiar!</span>
        </div>



      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-3" style="margin: auto;">
          <button (click)="guardar();" [disabled]="loadingSave" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>

          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="row pb-2 d-flex justify-content-center">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Puntos actuales</h4>
        <div class="card-text text-center">
          <label>{{ puntos_actuales | currency:"RD$ " }}</label>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Puntos depositados</h4>
        <div class="card-text text-center">
          <label>{{ puntos_depositados | currency:"RD$ " }}</label>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Total de premios ganados</h4>
        <div class="card-text text-center">
          <label>{{ total_premios | currency:"RD$ " }}</label>
        </div>
      </div>
    </div>
  </div>

  <my-tabs class="container" *ngIf="cliente.jugadas">

    <div class="row m-4">
      <div class="col-5">
        <input type="date" name="start_date" class="form-control" [(ngModel)]="start_date">
      </div>
  
      <div class="col-5">
        <input type="date" name="end_date" class="form-control" [(ngModel)]="end_date">
      </div>

      <div class="col-2">
        <button class="btn btn-secondary" (click)="filterDate()"><i class="las la-filter"></i> Filtrar</button>
      </div>
    </div>

    <my-tab tabTitle="Historial de jugadas">
      <table class="table row-border hover" datatable [dtOptions]="dtOptions">
        <thead class="bg-gray-100">
          <tr>
            <th>ID</th>
            <th>Monto</th>
            <th>Estado</th>
            <th>Fecha creación</th>
            <th>Acciones</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of cliente.jugadas">
            <td>{{ data.id }}</td>
            <td>{{ data.monto | currency:"RD$" }}</td>
            <td>
              <div [ngSwitch]="data.status">
                <div *ngSwitchCase="2" class="bg-danger text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Rechazada</div>
                <div *ngSwitchCase="1" class="bg-success text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Aprobada</div>
                <div *ngSwitchCase="0" class="bg-warning text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Pendiente</div>
              </div>
            </td>
            <td>{{ req.formatDate(data.created_at, 'LL') }}</td>
            <td style="text-align: center;" class="acciones">

              <!-- <a href="javascript:;" class="btn btn-sm btn-light-info btn-icon" title="Descargar">
                  <i class="la la-download"></i>
                </a> -->

              <a href="javascript:;" (click)="setApproveData(data)"
                data-toggle="modal" data-target="#view"
                *ngIf="(currentMenu && currentMenu.remove) || req.loggedUser.role_id === 1"
                class="btn btn-sm btn-light-primary btn-icon" title="Ver">
                <i class="la la-eye"></i>
              </a>
            
            </td>
        </tr>
        </tbody>
      </table>
    </my-tab>
    <my-tab tabTitle="Historial de premios">
      <table class="table row-border hover" *ngIf="cliente.jugadas" datatable [dtOptions]="dtOptions">
        <thead class="bg-gray-100">
          <tr>
            <th>Order ID</th>
            <th>Sorteo</th>
            <th>Números</th>
            <th>Monto Apostado</th>
            <th>Monto Ganado</th>
            <th>Estado</th>
            <th>Fecha creación</th>
            <th>Acciones</th>
        </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let items of premiados">

            <tr *ngFor="let data of items.details">
              <td>{{ data.order_id }}</td>
              <td>{{ data.sorteo.nombre }} ({{ data.tipo_juego.nombre }})</td>
              <td>
                <ng-container *ngFor="let numero of data.premiado.numeros_posiciones; let i = index;">
                  {{ numero.numero }} ({{ numero.nombre_posicion }}) <span *ngIf="data.premiado.numeros_posiciones.length != i+1"> - </span>
                </ng-container>
              </td>
              <td>{{ data.premiado.total_apostado | currency:"RD$" }}</td>
              <td>{{ data.premiado.total_ganado | currency:"RD$" }}</td>
              <td>
                <div [ngSwitch]="items.status">
                  <div *ngSwitchCase="1" class="bg-success text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Pagado</div>
                  <div *ngSwitchCase="0" class="bg-warning text-white font-weight-bold btn-sm px-4 text-center font-size-base ml-2">Pendiente</div>
                </div>
            </td>
              <td>{{ req.formatDate(data.created_at, 'LL') }}</td>
            <td style="text-align: center;" class="acciones">
  
              <!-- <a href="javascript:;" class="btn btn-sm btn-light-info btn-icon" title="Descargar">
                  <i class="la la-download"></i>
                </a> -->
  
              <a href="javascript:;" (click)="setApproveData(items)"
                 data-toggle="modal" data-target="#view"
                 *ngIf="(currentMenu && currentMenu.remove) || req.loggedUser.role_id === 1"
                 class="btn btn-sm btn-light-primary btn-icon" title="Ver">
                <i class="la la-eye"></i>
              </a>
             
            </td>
          </tr>
          </ng-container>
        </tbody>
      </table>
    </my-tab>
  </my-tabs>

  <div class="modal fade" id="view"  tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ticket #{{ approveData?.id }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="approveData?.details">
                <ng-container *ngFor="let detail of approveData?.details">
                    <h5>{{ detail?.sorteo.nombre }} - {{ (detail.super_pale)?'Super Palé':detail?.tipo_juego.nombre }}  <kbd>{{ detail?.monto | currency:"RD$"}}</kbd></h5>

                    <span>
                        {{ detail?.numeros }}
                    </span>
                </ng-container>
                
            </ng-container>
            <h6>Total: {{ approveData?.monto | currency:"RD$"}}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
