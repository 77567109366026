import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.css']
})
export class SelectSearchComponent implements OnInit {

  	@Input() data: any[] = [];
	@Input() id = 'id';
	@Input() text = 'text';
	@Input() name = 'name';
	@Input() optional = '';
	@Input() validation;
	@Input() placeholder = 'Filtrar...';
	@Input() multiple = false;
	@Output() value = new EventEmitter();

	@Input() selecteds: any;


	ngOnInit(){
	}

	onChange(){
		this.value.emit(this.selecteds)
	}
}
