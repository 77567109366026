import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";
import {environment} from "../../../environments/environment.prod";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Departamento, Usuario} from "../../../interfaces/interfaces";
import {ValidForm} from "../../../enums/enums";
declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  avatar:any;
  image:any;
  img_default:string;

  departamentos: Departamento[];
  supervisores: Usuario[];
  contrasena_actual:string;
  contrasena_nueva:string;
  repita_contrasena_nueva: string;
  validation:any;
  loadingSave:boolean;
  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  async ngOnInit() {
    this.avatar = new KTImageInput('kt_image_2');
    this.img_default = environment.default_avatar_boy;
    this.avatar.on('change', async (data) => {
      this.req.loggedUser.avatar = await this.req.getDataUrlFromFile(data.input.files[0]);
      this.req.loggedUser.avatar = String(await this.req.getDataUrlFromFile(data.input.files[0])).replace('data:image/png', 'data:image/jpeg');
    });


    this.validation = FormValidation.formValidation(
      KTUtil.getById('kt_login_signin_form'),
      {
        fields: {
          nombres: {
            validators: {
              notEmpty: {
                message: 'Nombre es requerido.'
              }
            }
          },
          apellidos: {
            validators: {
              notEmpty: {
                message: 'Apellido es requerido.'
              }
            }
          },
          usuario: {
            validators: {
              notEmpty: {
                message: 'Usuario es requerido.'
              }
            }
          },
          email: {
            validators: {
              notEmpty: {
                message: 'Email es requerido.'
              }
            }
          },
          estado: {
            validators: {
              notEmpty: {
                message: 'Estado es requerido.'
              }
            }
          }
        },
        plugins: {
          trigger: new FormValidation.plugins.Trigger(),
          submitButton: new FormValidation.plugins.SubmitButton(),
          //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
          bootstrap: new FormValidation.plugins.Bootstrap()
        }
      });
  }
  async guardar(){
    const status = await this.validation.validate();
    if (ValidForm.VALID === status){
      if (this.contrasena_nueva !== this.repita_contrasena_nueva){
        Swal.fire(
          '',
          'Contraseñas inválidas. Por favor verifique.',
          'error'
        );
        return;
      }
      this.req.loggedUser.contrasena = this.contrasena_nueva;
      this.loadingSave  = true;
      const response    = await this.req.POST('guardar_usuario', this.req.loggedUser);
      this.loadingSave  = false;
      this.req.setLoggedUser(this.req.loggedUser);
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        Swal.fire(
          '',
          '<p>Se ha actualizado satisfactoriamente su perfil.</p>',
          'success'
        );

      }
    }
  }
}
