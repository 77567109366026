<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          Edita tú Perfil
        </h5>
        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class=" container ">
      <!--begin::Profile Personal Information-->
      <div class="d-flex flex-row">
        <!--begin::Aside-->
        <!--end::Aside-->
        <!--begin::Content-->
        <div class="flex-row-fluid ml-lg-8">
          <!--begin::Card-->
          <div class="card card-custom card-stretch">
            <!--begin::Header-->
            <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">Información Personal</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Actualiza tu información</span>
              </div>
              <div class="card-toolbar">
                <button type="button" class="btn btn-success mr-2" [disabled]="loadingSave" (click)="guardar()">
                  <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
                  <span *ngIf="!loadingSave">
                    <i class="la la-save"></i>
                          Guardar Cambios
                  </span>
                </button>
              </div>
            </div>
            <!--end::Header-->

            <!--begin::Form-->
            <form class="form" id="kt_login_signin_form">
              <!--begin::Body-->
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Avatar</label>
                  <div class="col-lg-9 col-xl-6">
                    <div class="image-input" id="kt_image_2" style="  width: 120px;display: block;margin-bottom: 20px;">
                      <div class="image-input-wrapper" style="background-image: url({{req.loggedUser.avatar || img_default}})"></div>
                      <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"/>
                        <input type="hidden" name="profile_avatar_remove"/>
                      </label>
                      <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    </div>
                    <span class="form-text text-muted">Tipos Permitidos:  png, jpg, jpeg.</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Nombres</label>
                  <div class="col-lg-9 col-xl-6">
                    <input [(ngModel)]="req.loggedUser.nombres" type="text" name="nombres" disabled
                           class="form-control form-control-lg form-control-solid" placeholder="Tús nombres">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Apellidos</label>
                  <div class="col-lg-9 col-xl-6">
                    <input  [(ngModel)]="req.loggedUser.apellidos" type="text" name="apellidos" disabled
                            class="form-control form-control-lg form-control-solid" placeholder="Tús apellidos">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Correo Electrónico</label>
                  <div class="col-lg-9 col-xl-6">
                    <input  [(ngModel)]="req.loggedUser.email" type="email" name="email" disabled
                            class="form-control form-control-lg form-control-solid" placeholder="Tú email">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Extensión</label>
                  <div class="col-lg-9 col-xl-6">
                    <input  [(ngModel)]="req.loggedUser.extension" type="text" name="extension" autocomplete="off"
                            class="form-control form-control-lg form-control-solid" placeholder="Tú extensión">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Flota</label>
                  <div class="col-lg-9 col-xl-6">
                    <input  [(ngModel)]="req.loggedUser.flota" type="text" name="extension" autocomplete="off"
                            class="form-control form-control-lg form-control-solid" placeholder="Tú flota">
                  </div>
                </div>
                <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-6">
                    <h5 class="font-weight-bold mt-10 mb-6">Actualiza tú contraseña</h5>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Contraseña nueva</label>
                  <div class="col-lg-9 col-xl-6">
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon-lock"></i></span></div>
                      <input type="password" class="form-control form-control-lg form-control-solid" name="contrasena_nueva"
                             placeholder="Contraseña nueva" [(ngModel)]="contrasena_nueva">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right">Repita contraseña nueva</label>
                  <div class="col-lg-9 col-xl-6">
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon-lock"></i></span></div>
                      <input type="password" class="form-control form-control-lg form-control-solid" name="repita_contrasena_nueva"
                             placeholder="Repita contraseña nueva" [(ngModel)]="repita_contrasena_nueva">
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </form>
            <!--end::Form-->
          </div>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Profile Personal Information-->
    </div>
    <!--end::Container-->
  </div>
</div>
<app-footer></app-footer>
