<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea un nuevo ' + modulo : 'Edita un ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_loteria_form" autocomplete="off">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-6">
          <label>Loteria</label>
          <select name="loteria"  class="form-control" [required]="true" (change)="loteriaChanged($event.target.value)">
                <option value="">Por favor seleccione</option>
                <ng-container *ngIf="loterias">
                  <option  [value]="data.id" *ngFor="let data of loterias">{{data.nombre}}</option>
                </ng-container>
              </select>
        </div>

        <div class="col-lg-6">
          <label>Sorteos</label>
          <select name="sorteo"  class="form-control" [required]="true" (change)="sorteoChanged($event.target.value)">
                <option value="">Por favor seleccione</option>
                <ng-container *ngIf="sorteos">
                  <option  [value]="data.id" *ngFor="let data of sorteos">{{data.nombre}}</option>
                </ng-container>
              </select>
        </div>

      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-12" style="margin: auto;" *ngIf="combinaciones_exist">
                <table class="table row-border hover">
                  <thead class="bg-gray-100" *ngIf="combinaciones_exist">
                  <tr>
                    <th>Combinaciones</th>
                    <th>Número ganador</th>
                    <!-- <th>Acciones</th> -->
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="!combinaciones_exist" >
                      <span>NO DATA</span>
                    </tr>
                    <tr *ngFor="let data of combinaciones; let x = index">
                      <td>
                        <span class="posicion">
                          <kbd>{{ data.nombre }}</kbd>
                        </span>
                      </td>
                    <td>
                      <input type="number" class="form-control input-valor" id="input{{ data.id }}"
                       data-posicion="{{ data.id }}" name="valor" [placeholder]="'00'" [(ngModel)]="values[x]" (change)="valueChange($event, x)"
                       [value]="data.valor" [required]="true">
                    </td>
                    <td style="text-align: center; display: none;" >
                      <a href="javascript:;" *ngIf="(currentMenu && currentMenu.edit) || req.loggedUser.role_id === 1" (click)="edit(data.id)" class="btn btn-sm btn-light-warning btn-icon" title="Editar">
                        <i class="la la-edit"></i>
                      </a>
            
                      <a href="javascript:;"
                         style="margin-left:5px;" (click)="setCurrentDelete(data)"
                         data-toggle="modal" data-target="#deleteRecord"
                         *ngIf="(currentMenu && currentMenu.remove) || req.loggedUser.role_id === 1"
                         class="btn btn-sm btn-light-danger btn-icon" title="Eliminar">
                        <i class="la la-remove"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
        </div>
        <div class="col-lg-3" style="margin: auto;">
          <button (click)="guardar();" [disabled]="loadingSave" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>

          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-footer></app-footer>