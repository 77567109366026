<div style="position: absolute;left: 48%;z-index: 99999" *ngIf="loading">
  <img src="assets/loading.gif" style="width:100px;height:auto">
  <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
</div>
<div *ngIf="!loading" class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea un nuevo ' + moduleName : 'Edita un ' + moduleName}}
        </h5>

        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <form class="form" id="kt_login_signin_form" #cForm="ngForm" (ngSubmit)="guardar(cForm)" >
    <div class="card-body">
      <div class="row">
        <ng-container *ngFor="let field of fields">
          <ng-container [ngSwitch]="field.type" *ngIf="(accion === ActionType.EDIT && field.edit === 1) || (accion === ActionType.CREATE && field.add === 1)">
            <div class="col-lg-6" style="margin-top:5px;">
              <label *ngIf="field.type !== InputType.CHECKBOX">{{field.display_name}}:<span *ngIf="field.details.required" style="color:#ff1919;"> (Obligatorio)</span></label>
              <input *ngSwitchCase="InputType.NUMBER" type="number" [(ngModel)]="field.value" class="form-control"
                     name="{{field.field}}" [placeholder]="field.display_name"
                     [disabled]="!canEdit && accion === ActionType.EDIT">
              <input *ngSwitchCase="InputType.TEXT" type="text" [(ngModel)]="field.value" class="form-control"
                     name="{{field.field}}" [placeholder]="field.display_name" [required]="field.details.required"  [readOnly]="field.details.readonly"
                     (change)="uniqueValue(field)" [disabled]="(!canEdit && accion === ActionType.EDIT) || field.details.disabled">

              <input *ngSwitchCase="InputType.PASSWORD" type="password" [(ngModel)]="field.value" class="form-control"
                     name="{{field.field}}" [placeholder]="field.display_name" [required]="field.details.required"
                     (change)="uniqueValue(field)" [disabled]="!canEdit && accion === ActionType.EDIT">
              
              <input *ngSwitchCase="InputType.IMAGE" type="hidden" name="{{field.field}}" accept=".png, .jpg, .jpeg" [disabled]="!canEdit && accion === ActionType.EDIT" [(ngModel)]="field.value" />
              <div *ngSwitchCase="InputType.IMAGE" class="image-input" id="kt_image_{{field.field}}" style="  width: 120px;display: block;margin-bottom: 20px;">
                <div class="image-input-wrapper" style="background-image: url({{field.value}})"></div>
                <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change image">
                  <i class="fa fa-pen icon-sm text-muted"></i>
                  <input type="file" (click)="changeImage()" name="image_{{field.field}}" accept=".png, .jpg, .jpeg" [disabled]="!canEdit && accion === ActionType.EDIT"/>
                  <input type="hidden" name="image_{{field.field}}_remove"/>
                </label>
                <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel image">
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>

              <select *ngSwitchCase="InputType.SELECT_DROPDOWN" [(ngModel)]="field.value"
                      [disabled]="!canEdit && accion === ActionType.EDIT"
                      name="{{field.field}}"  class="form-control" [required]="field.details.required">
                <option value="">Por favor seleccione</option>
                <ng-container *ngIf="field.details">
                  <option  [value]="data" *ngFor="let data of field.details.options">{{data}}</option>
                </ng-container>
              </select>
              

              <ng-container *ngSwitchCase="InputType.SELECT_MULTIPLE">
                <app-select [data]="field.details.MultipleData" [multiple]="true" 
                *ngIf="!loading" [text]="nombre" 
                (value)="field.details.values=$event" [selecteds]="field.details.selecteds" style="width: 100%;"></app-select>
              </ng-container>

              <select *ngSwitchCase="InputType.RELATIONSHIP" [(ngModel)]="field.value"
                      name="{{field.details.column.toLowerCase()}}"  class="form-control"
                      [disabled]="!canEdit && accion === ActionType.EDIT"
                      [required]="field.details.required">
                <option value="">Por favor seleccione</option>
                <ng-container *ngIf="field.options">
                  <option  [value]="data.id" *ngFor="let data of field.options">{{data.text}}</option>
                </ng-container>
              </select>
              <input *ngSwitchCase="InputType.COLOR" [(ngModel)]="field.value"
                     name="{{field.field}}" [required]="field.details.required"
                     [disabled]="!canEdit && accion === ActionType.EDIT"
                     class="form-control" type="color" id="example-color-input"/>
              <input *ngSwitchCase="InputType.DATE_MONTH" [(ngModel)]="field.value"
                     name="{{field.field}}" [required]="field.details.required"
                     [disabled]="!canEdit && accion === ActionType.EDIT"
                     class="form-control" type="month"/>

              <input *ngSwitchCase="InputType.DATE" [(ngModel)]="field.value"
                     name="{{field.field}}" [required]="field.details.required"
                     [disabled]="!canEdit && accion === ActionType.EDIT"
                     class="form-control" type="date"/>

              <input *ngSwitchCase="InputType.TIMESTAMP" [(ngModel)]="field.value"
                     name="{{field.field}}" [required]="field.details.required"
                     [disabled]="!canEdit && accion === ActionType.EDIT"
                     class="form-control" type="datetime-local"  id="example-datetime-local-input"/>

              <input *ngSwitchCase="InputType.TIME" [(ngModel)]="field.value"
                    name="{{field.field}}" [required]="field.details.required"
                    [disabled]="!canEdit && accion === ActionType.EDIT"
                    class="form-control" type="time"  id="example-datetime-local-input"/>

              <div class="checkbox-inline" *ngSwitchCase="InputType.CHECKBOX" >
                <label class="checkbox checkbox-outline checkbox-success">
                  <input type="checkbox" name="{{field.field}}" [(ngModel)]="field.value" [disabled]="!canEdit && accion === ActionType.EDIT"/>
                  <span></span>
                  {{field.display_name}}
                </label>
              </div>
              <span *ngIf="field.type !== InputType.CHECKBOX" class="form-text text-muted">Por favor, Introduzca el campo {{field.display_name}}</span>
            </div>
          </ng-container>

        </ng-container>

        <google-map [options]="options" *ngIf="hasLocation" style="width: 100%;">
          <map-marker #marker="mapMarker" *ngFor="let markerPosition of markerPositions; let i = index"
                      [position]="markerPosition" 
                      [options]="markerOptions">  
            <map-info-window>
              <!--<a href="javascript:void(0)" (click)="goToURL(markerPosition)" >
                Abrir en google maps
              </a>-->
            </map-info-window>          
          </map-marker>
        </google-map>

      </div>
      <div class="row">
        <div class="col-lg-3" style="margin: auto;">
          <button type="button" (click)="generateCode()" *ngIf="table === 'cupones'"
                  class="btn btn-success font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Generar Código
          </button>
        </div>
      </div>
      <div class="row" style="margin-top:20px;" *ngIf="table === 'roles' && !loading">
        <div class="col-xl-12 col-xxl-12">
          <div class="row">
            <div class="col-lg-9 col-xl-6">
              <h3 class="kt-section__title kt-section__title-md">
                <input name="all" type="checkbox" (click)="togglePermisos('all')" [checked]="hasAllPermisos()" >
                Permisos
              </h3>
            </div>
          </div>
          <div class="form-group row fv-plugins-icon-container ">
            <ng-container *ngFor="let permission of modulePermissions">
              <div class="col-lg-6" *ngIf="single_business && permission.table_name !== 'empresas'">
                <label class="col-xl-12 col-lg-12 col-form-label" style="text-transform: capitalize;font-weight: bold;">
                  <input [name]="permission.table_name.replace('_',' ')" type="checkbox" (click)="togglePermisos(permission)" [checked]="hasRowPermisos(permission)" >
                  {{permission.table_name.replace("_"," ")}}
                </label>
                <div class="col-lg-9 col-xl-6">
                  <div class="checkbox-inline" >
                    <label class="checkbox" *ngFor="let action of permission.permissions">
                      <input [name]="action.key" type="checkbox" [(ngModel)]="action.value">
                      <span></span>
                      {{action.label}}
                    </label>
                  </div>
                  <div class="fv-plugins-message-container"></div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

      </div>
    </div>


    <div class="card-footer">
      <div class="row">
        <div class="col-lg-3" style="margin: auto;">


          <button type="submit"  [disabled]="!cForm.valid" class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
            <span *ngIf="!loadingSave">
              <i class="la la-save"></i>
              {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
            </span>
          </button>
          <button [disabled]="loadingSave" type="reset" (click)="nav.back()" class="btn btn-warning font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-history"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>

</div>


