<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{accion === 'crear'? 'Crea una nueva ' + modulo : 'Edita una ' + modulo}}
        </h5>


        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <div class="d-flex align-items-center" id="kt_subheader_search">
        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;left: 50%;z-index: 99999" *ngIf="loading">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
  </div>

  <form class="form" id="kt_login_signin_form" autocomplete="off" #cForm="ngForm">
    <div class="card-body">
      <div class="form-group row">
        <div class="col-lg-6">
          <label>Selecciona el cliente</label>
          <app-select-search [data]="clientes" *ngIf="!loading" [selecteds]="selected" [name]="'nombres'" [optional]="'telefono'" [placeholder]="'Buscar'" (value)="cliente=$event"></app-select-search>
        </div>
        <div class="col-lg-6">
          <label>Acción</label>
          <select class="form-control" [(ngModel)]="cartera.tipo" name="accion" title="Selecciona">
            <option value="2">Acreditar +</option>
            <option value="1">Debitar -</option>
          </select>
        </div>
        <div class="col-lg-6">
          <label>Monto</label>
          <input type="number" name="monto" class="form-control" placeholder="00" [(ngModel)]="cartera.monto">
        </div>
        <div class="col-lg-6">
          <label>Descripción</label>
          <textarea class="form-control" name="descripcion" [(ngModel)]="cartera.descripcion" cols="10" rows="1" placeholder="Descripción"></textarea>
        </div>
      </div>
      <div class="col-lg-12 d-flex justify-content-center align-items-center">
        <button type="submit" (click)="guardar()"  class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
          <img src="assets/loading-white.gif" width="20" *ngIf="loadingSave">
          <span *ngIf="!loadingSave">
            <i class="la la-save"></i>
            {{accion === 'crear' ? 'Guardar' : 'Actualizar'}}
          </span>
        </button>
      </div>
    </div>
  </form>
</div>

<app-footer></app-footer>