import { Component, OnInit } from '@angular/core';
import {Limite} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
import {environment} from "../../../environments/environment.prod";
declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
declare var $ : any;
@Component({
  selector: 'app-limites-add-or-edit',
  templateUrl: './limites-add-or-edit.component.html',
  styleUrls: ['./limites-add-or-edit.component.css']
})
export class LimitessAddOrEditComponent implements OnInit {
  modulo = 'limites';
  table   = 'limites'
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  limite:Limite = new Limite();
  ActionType: ActionType;
  start_date:any = '';
  end_date:any= '';
  sorteosData:any = [];
  loteriasData:any = [];
  sorteos:any;
  selecteds:any = [];
  tipos_juego:any = [];
  select_tipo_juego = 0;
  tipoJuegoData:any = [];
  limites:any = [];
  limites_select:any = [];
  tipo_limite = 0;
  limite_valor = 0;
  dtOptions: DataTables.Settings = {};
  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      lengthChange: false,
      order:[]
    };


    this.actRoute.queryParamMap.subscribe(async params => {
      var id = parseInt(params.get('id'));
      if(id){
        this.accion = ActionType.EDIT;

        const response = await this.req.GET('limite?&id=' + id);
        this.limite = response.response;

        console.log(this.limite)
        await this.loadData();
        await this.loadSorteos();

        this.selecteds = this.limite.sorteos
        this.limite.id = id;
        this.limites_select = this.limite.details.map(detail => {
          detail.valores = detail.numeros.split('-');
          detail.limite_valor = detail.valor_tipo_limite;
          return detail;
        });

        console.log(this.limites_select)
      }
      if(ActionType.CREATE === this.accion) {
        this.limite.estado = 'Activo';
        this.limite.tipo = -1;
        this.limite.loteria_id = 0;
        this.limite.tipo_juego_id = 0;
        await this.loadData();
      }
      
      this.loading  = false;
    });
  }
  async guardar(){
      this.loadingSave  = false;
      var data = {
        data:this.limite,
        sorteos:this.sorteos,
        limites:this.limites_select,
        user_id: this.req.loggedUser.id
      }

      const response    = await this.req.POST('limites', data);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        this.nav.push('limites');
        Swal.fire(
          '',
          '<p>Se ha ' + accion + ' satisfactoriamente una ' + this.modulo + '.</p>',
          'success'
        );
      }
  }

  async loadData(){
    this.loteriasData = await this.req.POST('data', {table:'loterias'})??[];
  }

  async loadSorteos(){
    var data = {
      "loteria_id":this.limite.loteria_id,
     }
    var response = await this.req.POST('sorteos_loteria',data);

    if(response.responseCode === "success"){
      this.sorteosData = response.response;
    }

    const res = await this.req.GET('loteria?id=' + this.limite.loteria_id);

    if(res.responseCode == 'success'){
      this.tipos_juego = res.response?.tipo_juego;

      this.tipos_juego.map(tipo_juego => {
        var arr = [];

        for (let i = 1; i < tipo_juego.cantidad_numeros + 1; i++) {
          arr.push(i);
        }

        tipo_juego.cantidad_numeros = arr;
        return tipo_juego;
      })
    }
  }


  async filterDate(){

    if(!this.start_date && !this.end_date) return;

    var data = {
      start_date:this.start_date,
      end_date:this.end_date,
      limite_id:this.limite.id
    }

    const response = await this.req.POST('filter_limite_data', data);

    if(response.responseCode == "success"){
      this.limite = response.response;
      await this.loadData();
    }
  }

  selectTipoJuego(){
   this.tipoJuegoData = this.tipos_juego.filter(tp => tp.id == this.select_tipo_juego).shift();
   this.limites = [];
  }

  saveLimites(){

    if(this.limites.length <= 0 || this.tipo_limite == 0 || this.limite_valor < 0){
      Swal.fire(
        '',
        'Campos vacíos',
        'error'
      );
      return;
    }

    var valores = this.limites;

    this.limites_select.push({
      tipo_juego_id:this.select_tipo_juego, 
      valores, 
      tipo_limite:this.tipo_limite, 
      limite_valor:this.limite_valor
    });

    this.limites_select.map(limite => {

      var tipo_juego = this.tipos_juego.filter(tp => tp.id == limite.tipo_juego_id);

      if(tipo_juego.length > 0){
        limite.tipo_juego = tipo_juego.shift();
      }

      return limite;
    });

    console.log(this.limites_select)
    this.limites = [];
  }

  removeLimite(index){
    this.limites_select.splice(index, 1)
  }
}
