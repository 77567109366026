import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as $ from 'jquery';

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {

	@Input() data: any[] = [];
	@Input() id = 'id';
	@Input() text = 'text';
	@Input() multiple = true;
	@Input() validation;
	@Input() placeholder = 'Filtrar...';
	@Output() value = new EventEmitter();

	@Input() selecteds: any;

	public settings = {
		singleSelection: false,
		idField: 'id',
		textField: 'nombre',
		selectAllText: 'Seleccionar todo',
		unSelectAllText: 'Deseleccionar todo',
		searchPlaceholderText: 'Buscar...',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};
	constructor() { }

	ngOnInit(): void {
		Object.assign({}, this.settings, { idField: this.id, textField:this.text, singleSelection: !this.multiple });
		this.orderBy('id', 'ASC');
		$("#dropdown-list .dropdown-list").append($("#footer_item").html());
		$("#footer_item").remove();
		//$("#limpiar").click(this.limpiar);
		$("#aplicar").click(this.aplicar);
	}

	onItemSelect(event){
		console.log(event);
	}
	
	onSelectAll(event){
		console.log(event);
	}
	
	changeData(){
		//[{a: 2}, {a: 3}].map(p=>p.a)
		if(this.multiple){
			this.value.emit(this.selecteds.map(p=>p[this.id]));
		} else {
			this.value.emit(this.selecteds);
		}
	}
	return_all(){
		this.value.emit(this.data.map(p=>p[this.id]));
	}
	return_none(){
		this.value.emit([]);
	}

	orderBy(campo, orden?){
		if(!orden){ orden = 'ASC'; }
		const _ = (dato) => (isNaN(parseFloat(dato))) ? dato : parseFloat(dato);
		// console.log(this.data)
		this.data = this.data.sort(function(a, b){
			if(orden == 'ASC'){
				return (_(a[campo]) > _(b[campo])) ? 1 : ( (_(a[campo]) < _(b[campo])) ? -1: 0 );
			} else {
				return (_(a[campo]) < _(b[campo])) ? 1 : ( (_(a[campo]) > _(b[campo])) ? -1: 0 );
			}
		});
	}

	limpiar(){
		if(this.multiple){ this.selecteds = []; } else { this.selecteds = null; }
	}

	aplicar(){
		$("#dropdown-list").click();
	}

}
