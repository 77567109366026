import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {Categoria, Cliente, Documento, Field, Menu, Module} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {NgForm} from "@angular/forms";
import {Nav} from "../../../services/nav.service";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {InputType} from "../../../enums/enums";
import {environment} from "../../../environments/environment.prod";
import Swal from "sweetalert2";
import {HttpEventType} from "@angular/common/http";
import * as moment from "moment";
import { ActivatedRoute, Router } from '@angular/router';
declare var toastr;
declare var KTImageInput;
@Component({
  selector: 'app-solicitud-retiros',
  templateUrl: './solicitud-retiros.component.html',
  styleUrls: ['./solicitud-retiros.component.css']
})
export class SolicitudRetirosComponent implements OnInit {
  
  module_id;
  moduleName;
  table;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger= new Subject();
  fields: Field[];
  datas:any;
  InputType = InputType;
  cliente: Cliente = new Cliente();
  categoria:Categoria = new Categoria();
  currentMenu: Menu;
  complete_load_clientes: number = 0;
  file_load_clientes:any;
  importCSV = false;
  downloadCSV = false; 
  module:Module;
  dt;
  approveData;
  dismissData;
  desc:any;
  constructor(public req: RequestService, private nav: Nav, private router: Router, private actRoute: ActivatedRoute) {

  }
async ngOnInit() {
  this.module_id = 119
  this.moduleName = "Solictud retiros";
  this.table = 'solicitud_retiros';
 
  this.importCSV = (this.table !== 'empresas');
  this.downloadCSV = (this.table !== 'empresas' && this.table != 'encuestas');

  if(this.module_id != null) {
    this.fields = await this.req.GET('module_fields?module=' + this.module_id);
    for (let field of this.fields){
      if (field.type === InputType.SELECT_DROPDOWN) {
        field.details.options = Object.keys(field.details.options).map(function (key) {
          return field.details.options [key];
        });
      }else if(field.type === InputType.RELATIONSHIP){
        field.options = await this.req.GET_URL(environment.relation_url + this.table + '/relation?type=' + field.field);
      }
    }
  }

  this.dtOptions = {
    pagingType: 'full_numbers',
    searching: false,
    lengthChange: false,
    order: [4,'desc']
  };

  const response= await this.req.GET('solicitud_retiros');
  
  if(response.responseCode == "success"){
    this.datas = response.response;
  }

  this.fields.map(f => {
    if(f.type === 'relationship'){
      f.field = f.field.toLowerCase().split('_')[2];
    }
    return f;
  });
  const campos = this.fields.filter(f => f.type == "relationship");
  this.datas.map(function(data){
    for(let x= 0; x < campos.length; x++)
    {
      const val = campos[x].options.find(c => c.id == data[campos[x].details.column]);
      data[campos[x].field] = (val != undefined) ? val.text : "";
    }
    return data;
  });
  this.dtTrigger.next();
}

buscar(form: NgForm){
  this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
    this.datas = null;
    dtInstance.destroy();
    form.form.value.table = this.table;
    form.form.value.empresa = this.req.loggedUser.empresa_selected.id;
    this.datas = await this.req.POST('data', form.form.value);
    this.dtTrigger.next();
  });
}

ngOnDestroy(): void {
  this.dtTrigger.unsubscribe();
}

async changeStatusRequest(id, estado){
  var data = {
    id:id,
    estado:estado,
    usuario:this.req.loggedUser.id,
    desc:this.desc
  }

  const res = await this.req.POST('update_solicitud_retiro', data);

  if (res.responseCode === 'error') {
    Swal.fire(
      '',
      res.message,
      'error'
    );
    return;
  }else{
    Swal.fire(
      '',
      'Estado actualizado',
      'success'
    );

    const response= await this.req.GET('solicitud_retiros');
  
    if(response.responseCode == "success"){
      this.datas = response.response;
    }
    // window.location.reload();
  }
}

setApproveData(data){
  this.approveData = data;
  console.log(data)
}

setDismissData(data){
  this.dismissData = data;
}

}
