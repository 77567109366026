import { Component, OnInit } from '@angular/core';
import {RequestService} from "../../../services/request.service";
import {environment} from "../../../environments/environment.prod";
import {Nav} from "../../../services/nav.service";
import {Usuario} from "../../../interfaces/interfaces";
declare var KTLayoutQuickUser;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  images_url = environment.images_url;
  APP_NAME = environment.project_name;
  constructor(public req: RequestService, private nav: Nav) {
    //
  }

  ngOnInit(): void {
    setTimeout(()=>{
     // this.loadScripts();
      KTLayoutQuickUser.init('kt_quick_user');
    },200);
  }

  logout(){
    window.localStorage.clear();
    this.req.loggedUser = new Usuario();
    this.req.menues     = null;
    this.nav.push('login');
  }

  loadScripts() {

    // This array contains all the files/CDNs
    const dynamicScripts = [
      'assets/plugins/global/plugins.bundle.js?v=7.0.6',
      'assets/plugins/custom/prismjs/prismjs.bundle.js?v=7.0.6',

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  goTareas() {
    this.nav.push('tareas',{module:'tareas'})
  }
}
