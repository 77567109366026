<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div style="position: absolute;left: 50%;z-index: 99999" *ngIf="!datas">
    <img src="assets/loading.gif" style="width:100px;height:auto">
    <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
</div>
  <div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
    <!--begin::Subheader-->
    <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
      <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            Módulo de {{moduleName}}
          </h5>
          <!--begin::Separator-->
          <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total" *ngIf="datas">{{datas.length}} Total</span>
          </div>
        </div>
        <div class="d-flex align-items-center" *ngIf="(currentMenu && currentMenu.add) || req.loggedUser.role_id === 1">
          <!--end::Button-->
          <!--begin::Button-->
          <a href="javascript:void(0)" (click)="create()" style="margin-right: 5px;"
             class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
            <i class="la la-plus"></i>
            Agregar {{moduleName}}
          </a>
  
          <app-manage-csv [canImport]="importCSV" [canDownload]="downloadCSV" [table]="table" [moduleName]="moduleName" ></app-manage-csv>
  
        </div>
      </div>
    </div>
    
  
    <div class="modal fade" id="cargar_archivo_clientes" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Cargar Archivo Clientes</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body" style="padding-top: 0px;">
              <div class="form-group row">
                <span id="kt_image_2" placement="top" triggers="mouseenter:mouseleave" style="margin-top: 40px;"
                      ngbPopover="Adjunta la plantilla con los clientes cargados.">
                  <label style="width: 230px;margin-left: auto;" class="btn btn-icon btn-sm btn-clean mr-2 dz-clickable"  data-action="change" >
                    Subir Archivo
                    <input style="display:none;" type="file" name="file" />
                  </label>
                </span>
                <div class="progress" style="height:5px;margin-top:2px;width: 100%" *ngIf="complete_load_clientes > 0">
                  <div class="progress-bar progress-bar-striped progress-bar-animated " role="progressbar" style="width: {{ complete_load_clientes}}%"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" >
      <div class="mb-7"  *ngIf="datas">
        <form #cForm="ngForm" (ngSubmit)="buscar(cForm)">
          <div class="row align-items-center">
            <div class="col-lg-12 col-xl-12">
              <div class="row align-items-center">
                <ng-container *ngFor="let field of fields" style="margin-top:10px;">
                  <div class="col-md-4 my-2 my-md-0"  *ngIf="field.browse === 1 && field.type !== InputType.IMAGE">
                    <div [ngSwitch]="field.type" >
                      <div class="d-flex align-items-center" style="margin-top:10px;">
                        <label class="mr-0 mb-0 d-none d-md-block col-md-4">{{field.display_name}}:</label>
                        <input *ngSwitchCase="InputType.NUMBER" type="number" [(ngModel)]="field.value" class="form-control"
                               name="{{field.field}}" [placeholder]="field.display_name">
                        <input *ngSwitchCase="InputType.TEXT" type="text" [(ngModel)]="field.value" class="form-control"
                               name="{{field.field}}" [placeholder]="field.display_name">
  
                        <select *ngSwitchCase="InputType.SELECT_DROPDOWN" [(ngModel)]="field.value"
                                name="{{field.field}}"  class="form-control">
                          <option value="">Todos</option>
                          <ng-container *ngIf="field.details">
                            <option  [value]="data" *ngFor="let data of field.details.options">{{data}}</option>
                          </ng-container>
                        </select>
  
                        <select *ngSwitchCase="InputType.RELATIONSHIP" [(ngModel)]="field.value"
                                name="{{field.details.column.toLowerCase()}}"  class="form-control">
                          <option value="">Todos</option>
                          <ng-container *ngIf="field.options">
                            <option  [value]="data.id" *ngFor="let data of field.options">{{data.text}}</option>
                          </ng-container>
                        </select>
  
                        <input *ngSwitchCase="InputType.DATE_MONTH" [(ngModel)]="field.value"
                               name="{{field.field}}"
                               class="form-control" type="month"  id="example-datetime-local-input"/>
  
                        <input *ngSwitchCase="InputType.DATE" [(ngModel)]="field.value"
                               name="{{field.field}}"
                               class="form-control" type="date"/>
                        <input *ngSwitchCase="InputType.TIMESTAMP" [(ngModel)]="field.value"
                               name="{{field.field}}"
                               class="form-control" type="date"/>
                        <div class="checkbox-inline" *ngSwitchCase="InputType.CHECKBOX" >
                          <label class="checkbox checkbox-outline checkbox-success">
                            <input type="checkbox" name="{{field.field}}" [(ngModel)]="field.value"/>
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-12 col-xl-12 mt-5 mt-lg-0" *ngIf="fields" style="margin-top:10px!important;">
              <button style="float: right;display: block;" type="submit" class="btn btn-success px-6 font-weight-bold" >
                <i class="la la-search"></i>
                Búscar
              </button>
            </div>
          </div>
        </form>
      </div>
      <!--begin: Datatable-->
      <table datatable [dtOptions]="dtOptions" class="row-border hover" [dtTrigger]="dtTrigger">
        <thead class="bg-gray-100" *ngIf="datas">
        <tr>
          <th>ID</th>
          <th>Loteria</th>
          <th>Sorteo</th>
          <th>Resultado</th>
          <th>Fecha</th>
          <th>Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of datas">
          <td>{{ data.id }}</td>
          <td>{{ data.loteria.nombre }}</td>
          <td>{{ data.sorteo }}</td>
          <td class="resultados">
            <ng-container *ngFor="let resultado of data.resultados; let i = index">
              <span>{{ resultado.numero }}</span>
            </ng-container>
          </td>
          <td>{{ req.formatDate(data.created_at, 'LL')  }}</td>
          <td style="text-align: center;" class="acciones">
            <a href="javascript:;" class="btn btn-sm btn-light-primary btn-icon" (click)="showGanadores(data.id)" title="Ver resultados">
              <i class="la la-eye"></i>
            </a>

            <a href="javascript:;" class="btn btn-sm btn-light-danger btn-icon" (click)="setCurrentDelete(data)" title="Ver resultados"
            data-toggle="modal" data-target="#deleteRecord">
              <i class="la la-trash"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <!--end: Datatable-->
    </div>
  </div>
  <div class="modal fade" id="deleteRecord" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header btn-danger">
          <h5 class="modal-title" style="color:white;">¿Está seguro que desea eliminar este registro?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <p>Solo podrás eliminar este resultados si el mismo no tiene ninguna orden a la cual se le aprobó el resultado.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="deleteRecord()" class="btn btn-success font-weight-bold" data-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>

 
  