import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Nav } from 'src/services/nav.service';
import { RequestService } from 'src/services/request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resultados-ganadores',
  templateUrl: './resultados-ganadores.component.html',
  styleUrls: ['./resultados-ganadores.component.css']
})
export class ResultadosGanadoresComponent implements OnInit {

  datas:any;
  dtOptions:DataTables.Settings = {};
  ganadores:any;
  posiciones:any;
  loading = true;
  v_resultados:any = [];
  constructor(public req: RequestService, private route: ActivatedRoute, private nav: Nav) { }

  async ngOnInit() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      lengthChange: false,
      order: []
    };

   

    await this.loadGanadores();
    this.loading = false;
  }

  async loadGanadores(){
    var param = this.route.snapshot.paramMap.get('id');

    if(!param){
      this.nav.push('resultados');
      return;
    }

    var datas = {
      id:param
    }
    const response = await this.req.POST('resultado', datas);
    if(response.responseCode == "success"){
      this.datas = response.response;
    }

    var data = {
      id:this.datas.sorteo_id
    }

    const res = await this.req.POST('sorteo', data);
    if(res.responseCode != "success"){
      console.log('Error al obtener el sorteo')
      return;
    }

    const posi = await this.req.GET('posiciones');
    
    if(posi.responseCode != "success"){
      console.log('Error al obtener las posiciones');
      return;
    }

    this.posiciones = posi.response;
    this.datas.ganadores.forEach(async ganador => {
      var tipo_juego = ganador.jugada.tipo_juego;
      const response = await this.req.GET('tipo_juego?module=tipo_juegos&id=' + tipo_juego.id);
      var numeros = JSON.parse(ganador.numeros_posiciones);
      ganador.numeros_posiciones = numeros.map(n => {
        var posi = this.posiciones.filter(p => p.id == n.posicion_id).shift();

        n.nombre_posicion = posi.nombre;
        return n;
      });
    });

    this.ganadores = this.datas.ganadores.sort((a,b)=> b.total_ganado-a.total_ganado);

  }

  async changeStatus(id, type){
    var data = {
      id:id, 
      estado:type
    }

    const response = await this.req.POST('change_status_resultado', data);
    
    if(response.responseCode == "success"){
      Swal.fire('Cambio exitoso', 'La jugada ha cambiado su estado', 'success');
      this.loadGanadores();
    }
  }

  async changeAllStatus(estado){

    if(!this.validarResultado()){
      return;
    }    

    if(this.ganadores.length < 1){
      Swal.fire('Jugadas insuficientes', 'Este resultado no tiene ganadores', 'error');
      return;
    }

    var ganadores = this.ganadores;
    var jugadas = ganadores.filter(g => g.estado != estado && g.estado != 2).map(gm=>gm.id);

    var data = {
      jugadas:jugadas, 
      estado:estado,
      user_id:this.req.loggedUser.id,
    }

    const response = await this.req.POST('change_all_status_resultado', data);

    if(response.responseCode == "success"){
      Swal.fire('Cambio exitoso', 'Las jugadas han cambiado su estado', 'success');
      this.nav.push('resultados')
    }else{
      Swal.fire(response.message, null, 'error');
    }
  }

  validarResultado(){
    var valido = true;
    var resultado = this.datas.resultados.map(result => result.numero)
    var validar = this.v_resultados.map(val => {
      if(val < 10){
        val = '0'+val;
      }

      return val;
    })
    validar = validar.join('-');
    resultado = resultado.join('-');

    
    if(validar != resultado){
      Swal.fire('Los valores no coinciden', 'Verifica nuevamente que este sea el resultado correcto al cual deseas aprobar sus ganancias', 'error');
      valido = false;
    }

    return valido;
  }

  approveData:any;
  setApproveData(data){
    this.approveData = data;
  }

}
