import {Component, NgZone, OnInit} from '@angular/core';
import {RequestService} from "../../../services/request.service";
import {Router} from "@angular/router";
import {Nav} from '../../../services/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public req: RequestService, private ngZone: NgZone, private nav: Nav, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit(): void {
    if (!this.req.loggedUser.empresa_selected){
      this.nav.push('seleccione-empresa');
    }
  }

}
