<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper d-flex flex-column flex-column-fluid" id="kt_content" style="height:100%;padding-top: 0px;background: #FFF;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          Módulo de {{modulo}}
        </h5>

        <!--begin::Separator-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
        <!--end::Separator-->
        <!--begin::Search Form-->
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total" *ngIf="datas">{{datas.length}} Total</span>
        </div>
      </div>
      <div class="d-flex align-items-center" *ngIf="(currentMenu && currentMenu.add) || req.loggedUser.role_id === 1">
        <!--end::Button-->
        <!--begin::Button-->
        <a href="javascript:void(0)" (click)="create()" style="margin-right: 5px;"
           class="btn btn-primary font-weight-bold btn-sm px-4 font-size-base ml-2">
          <i class="la la-plus"></i>
          Agregar {{modulo}}
        </a>

      </div>
    </div>

  </div>
  <div class="card-body">
    <div style="position: absolute;left: 48%;z-index: 99999" *ngIf="!datas">
      <img src="assets/loading.gif" style="width:100px;height:auto">
      <p style="position: relative;left: 18px;z-index: 8888888;top: -20px;">Cargando..</p>
    </div>
    <!--begin: Datatable-->
    <table datatable [dtOptions]="dtOptions" class="row-border hover" [dtTrigger]="dtTrigger">
      <thead *ngIf="datas">
      <tr>
        <th>ID</th>
        <th>Nombre</th>
        <th>Loteria</th>
        <th>Sorteos</th>
        <th>Tipo</th>
        <th>Fecha Inicio</th>
        <th>Fecha Fin</th>
        <th>Estado</th>
        <th>Fecha de creación</th>
        <th>Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of datas">
        <td>{{data.id}}</td>
        <td>{{ data.nombre }}</td>
        <td>{{data.loteria.nombre}}</td>
        <td>
          <ng-container *ngFor="let sorteo of data.sorteos; let i = index">
            {{ sorteo.nombre }} <span *ngIf="data.sorteos.length != i+1"> - </span>
          </ng-container>
        </td>
        <td>
          <ng-container [ngSwitch]="data.tipo">
            <ng-container *ngSwitchCase="0">
                Monto por jugada
            </ng-container>
            <ng-container *ngSwitchCase="1">
              Combinación
            </ng-container>
          </ng-container>
        </td>
        <td>{{ formatDate(data.start_date) }}</td>
        <td>{{ formatDate(data.end_date) }}</td>
        <td>{{ data.estado }}</td>
        <td>{{ formatDate(data.created_at)}}</td>
        <td class="acciones">
          <a href="javascript:void(0);"
             *ngIf="(currentMenu && currentMenu.edit) || req.loggedUser.role_id === 1"
             (click)="edit(data.id)" class="btn btn-sm btn-primary btn-icon" title="Editar">
            <i class="la la-edit"></i>
          </a>
          <a href="javascript:;" (click)="setDismissData(data)"
               data-toggle="modal" data-target="#deleteRecord"
               *ngIf="(currentMenu && currentMenu.remove) || req.loggedUser.role_id === 1"
               class="btn btn-sm btn-light-danger btn-icon" title="Declinar" [ngClass]="{'disabled': data.status >= 1}">
              <i class="la la-remove"></i>
            </a>
        </td>
      </tr>
      </tbody>
    </table>
    <!--end: Datatable-->
  </div>
</div>

<div class="modal fade" id="deleteRecord" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header btn-danger">
        <h5 class="modal-title" style="color:white;">¿Está seguro que desea eliminar este registro?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="deleteRecord(dismissData?.id)" class="btn btn-success font-weight-bold" data-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
