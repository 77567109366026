
<!--end::Head-->

<!--begin::Body-->

<!--begin::Main-->
<div class="d-flex flex-column flex-root" style="height: 100vh;">
  <!--begin::Login-->
  <div class="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
    <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style="background-image: url('assets/media/bg/bg-3.jpg');">
      <div class="login-form text-center p-7 position-relative overflow-hidden">
        <!--begin::Login Header-->
        <div class="d-flex flex-center">
          <a href="javascript:void(0);">
            <img src="assets/media/logos/logotnrd.png" class="max-h-150px" alt=""/>
          </a>
        </div>
        <!--end::Login Header-->

        <!--begin::Login Sign in form-->
        <div class="login-signin">
          <div class="mb-10">
            <h3>Accede a {{req.PROJECT_NAME}}</h3>
            <div class="text-muted font-weight-bold">Introduce tus crédenciales para entrar:</div>
          </div>
          <form class="form" id="kt_login_signin_form">
            <div class="form-group mb-5">
              <input [(ngModel)]="req.loggedUser.email" class="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="username" autocomplete="off" />
            </div>
            <div class="form-group mb-5">
              <input [(ngModel)]="req.loggedUser.contrasena" class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Contraseña" name="password" />
            </div>
            <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
              <div class="checkbox-inline">
                <label class="checkbox m-0 text-muted">
                  <input type="checkbox" name="remember" />
                  <span></span>
                  Recordarme
                </label>
              </div>
              <!--<a href="javascript:;" id="kt_login_forgot" class="text-muted text-hover-primary">Forget Password ?</a> -->
            </div>
            <button (click)="login()" id="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" [disabled]="loading">
              <img src="assets/loading-white.gif" width="20" *ngIf="loading">
              <span *ngIf="!loading">Entrar</span>
            </button>
          </form>
        </div>
        <!--end::Login Sign in form-->

        <!--begin::Login Sign up form-->
        <div class="login-signup">
          <div class="mb-20">
            <h3>Sign Up</h3>
            <div class="text-muted font-weight-bold">Enter your details to create your account</div>
          </div>
          <form class="form" id="kt_login_signup_form">
            <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Fullname" name="fullname" />
            </div>
            <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" autocomplete="off" />
            </div>
            <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" />
            </div>
            <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Confirm Password" name="cpassword" />
            </div>
            <div class="form-group mb-5 text-left">
              <div class="checkbox-inline">
                <label class="checkbox m-0">
                  <input type="checkbox" name="agree" />
                  <span></span>
                  I Agree the <a href="javascript:void(0);" class="font-weight-bold ml-1">terms and conditions</a>.
                </label>
              </div>
              <div class="form-text text-muted text-center"></div>
            </div>
            <div class="form-group d-flex flex-wrap flex-center mt-10">
              <button id="kt_login_signup_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">Sign Up</button>
              <button id="kt_login_signup_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2">Cancel</button>
            </div>
          </form>
        </div>
        <!--end::Login Sign up form-->

        <!--begin::Login forgot password form-->
        <div class="login-forgot">
          <div class="mb-20">
            <h3>Forgotten Password ?</h3>
            <div class="text-muted font-weight-bold">Enter your email to reset your password</div>
          </div>
          <form class="form" id="kt_login_forgot_form">
            <div class="form-group mb-10">
              <input class="form-control form-control-solid h-auto py-4 px-8" type="text" placeholder="Email" name="email" autocomplete="off"/>
            </div>
            <div class="form-group d-flex flex-wrap flex-center mt-10">
              <button id="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">Request</button>
              <button id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2">Cancel</button>
            </div>
          </form>
        </div>
        <!--end::Login forgot password form-->
      </div>
    </div>
  </div>
  <!--end::Login-->
</div>
<!--end::Main-->

