import { Component, OnInit } from '@angular/core';
import {Cliente} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
import {environment} from "../../../environments/environment.prod";
declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
declare var $ : any;
@Component({
  selector: 'app-clients-add-or-edit',
  templateUrl: './clients-add-or-edit.component.html',
  styleUrls: ['./clients-add-or-edit.component.css']
})
export class ClientsAddOrEditComponent implements OnInit {
  modulo = 'clientes';
  table   = 'clientes'
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  cliente:Cliente = new Cliente();
  ActionType: ActionType;
  avatar:any;
  image:any;
  img_default:string;
  loadingResetPass:boolean;
  empresas:any;
  puntos_actuales = 0;
  puntos_depositados = 0;
  total_premios = 0;
  premiados:any;
  posiciones:any;
  start_date:any = '';
  end_date:any= '';

  dtOptions: DataTables.Settings = {};
  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {
    this.avatar = new KTImageInput('kt_image_2');
    this.img_default = environment.default_avatar_boy;
    this.avatar.on('change', async (data)=>{
      this.cliente.avatar = await this.req.getDataUrlFromFile(data.input.files[0])
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      lengthChange: false,
      order:[0,'desc']
    };

    const posi = await this.req.GET('posiciones');
    
    if(posi.responseCode != "success"){
      console.log('Error al obtener las posiciones');
      return;
    }

    this.posiciones = posi.response;

    this.actRoute.queryParamMap.subscribe(async params => {
      this.cliente.id = parseInt(params.get('id'));
      if(this.cliente.id){
        this.accion = ActionType.EDIT;

        const response = await this.req.GET('cliente?&id=' + this.cliente.id);
        this.cliente = response.response;

        await this.loadData();
      }
      if(ActionType.CREATE === this.accion) {
        this.cliente.estado = 'Activo';
      }
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 200);
      this.loading  = false;
      this.validation = FormValidation.formValidation(
        KTUtil.getById('kt_login_signin_form'),
        {
          fields: {
            nombres: {
              validators: {
                notEmpty: {
                  message: 'Nombre es requerido.'
                }
              }
            },
            telefono: {
              validators: {
                notEmpty: {
                  message: 'Apellido es requerido.'
                }
              }
            },
            email: {
              validators: {
                notEmpty: {
                  message: 'Email es requerido.'
                }
              }
            },
            cedula_pasaporte: {
              validators: {
                notEmpty: {
                  message: 'Cedula o pasaporte es requerido.'
                }
              }
            },
            estado: {
              validators: {
                notEmpty: {
                  message: 'Estado es requerido.'
                }
              }
            }
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
            bootstrap: new FormValidation.plugins.Bootstrap()
          }
        }
      );
    });
  }
  async guardar(){
    // console.log(this.cliente);return
    const status = await this.validation.validate();
    if (ValidForm.VALID === status){
      this.loadingSave  = true;
      this.cliente.contrasena = null;
      const response    = await this.req.POST('guardar_cliente', this.cliente);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        this.nav.push('clients');
        Swal.fire(
          '',
          '<p>Se ha ' + accion + ' satisfactoriamente una ' + this.modulo + '.</p>',
          'success'
        );
      }
    }
  }

  async validateExist(field, value) {
    const response = await this.req.GET('validate_exist?module=' + this.table + '&field=' + field + '&value=' + value + '&id=' + this.cliente.id);
    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      if(field === 'email') {
        this.cliente.email = null;
      }
      await this.validation.validate();
      return;
    }
  }

  async loadData(){
    this.puntos_actuales = this.cliente.puntos??0;
    this.puntos_depositados = this.cliente.cartera.filter(cartera => cartera.tipo == 2).reduce((a, b) => a + b.monto, 0);
    this.total_premios = this.cliente.cartera.filter(cartera => cartera.tipo == 3).reduce((a, b) => a + b.monto, 0);
    this.premiados = this.cliente.jugadas.filter(jugada => {
      var data = jugada.details.filter(detail => {
        if(detail.premiado != null){

          var numeros = JSON.parse(detail.premiado.numeros_posiciones);
          detail.premiado.numeros_posiciones = numeros.map(n => {
            var posi = this.posiciones.filter(p => p.id == n.posicion_id).shift();

            n.nombre_posicion = posi.nombre;
            return n;
          });

          return detail;
        }
      });
      
      if(data.length > 0){
        jugada.details = data;
        return jugada;
      }
    });
  }

  approveData:any;
  setApproveData(data){
    this.approveData = data;
  }

  async filterDate(){

    if(!this.start_date && !this.end_date) return;

    var data = {
      start_date:this.start_date,
      end_date:this.end_date,
      cliente_id:this.cliente.id
    }

    const response = await this.req.POST('filter_cliente_data', data);

    if(response.responseCode == "success"){
      this.cliente = response.response;
      await this.loadData();
    }
  }
}
