<app-principal-menu></app-principal-menu>
<app-header></app-header>
<div class="content wrapper" id="kt_content" style="margin-top:0px;padding-top:0px;">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
    <div class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Page Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          Dashboard </h5>
        <!--end::Page Title-->
        <!--begin::Actions-->
        <!--end::Actions-->
      </div>
      <!--end::Info-->

      <!--end::Toolbar-->
    </div>
  </div>
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid" style="background: white;">
    <!--begin::Container-->
    <div class=" container ">
      <div class="row">
        <div class="col-lg-2" style="background: #3365ce; padding: 20px; border: 1px solid #EEF0F8; height: 118px;">
          <h4 class="text-align-center" style="color: white;">Pendiente por Pagar</h4>
          <h4 class="text-align-center" style="color: white;">{{indicadores.total | currency:"RD$"}}</h4>
        </div>

        <div class="col-lg-2" style="background: #cd133c; padding: 20px; border: 1px solid #EEF0F8; height: 118px;">
          <h4 class="text-align-center" style="color: white;">Inventario Actual</h4>
          <h4 class="text-align-center" style="color: white;">{{indicadores.inventario}}</h4>
        </div>

        <div class="col-lg-2" style="background: #79cd13; padding: 20px; border: 1px solid #EEF0F8; height: 118px;">
          <h4 class="text-align-center" style="color: white;">Inversion Actual</h4>
          <h4 class="text-align-center" style="color: white;">{{indicadores.inversion_total | currency:"RD$"}}</h4>
        </div>
      </div>
      <!--end::Row-->
      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
<app-footer></app-footer>
