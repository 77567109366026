<div class="d-flex flex-column-fluid" style="height: 100vh;background-image: url('assets/media/bg/bg-3.jpg');">
    <!--begin::Login-->

        <div class="container">
            <div class="login-form text-center p-7 position-relative overflow-hidden">
                <!--begin::Login Header-->
                <div class="d-flex flex-center mb-15">
                    <a href="#">
                        <img src="assets/media/logos/logo-tradekit.png" class="max-h-75px" alt=""/>
                    </a>
                </div>
                <div class="d-flex flex-center mb-15">
                    <h2>Seleccione una empresa</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4" *ngFor="let empresa of req.loggedUser.empresas">
                    <!--begin::Stats Widget 4-->
                    <div class="card card-custom card-stretch gutter-b" style="cursor:pointer;" (click)="selectEmpresa(empresa)">
                        <!--begin::Body-->
                        <div class="card-body d-flex align-items-center py-0 mt-8">
                            <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                <a href="javascript:void(0);" class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">
                                    {{empresa.nombre}}
                                </a>
                                <span class="font-weight-bold text-muted font-size-lg">
                                    {{empresa.direccion}}
                                </span>
                            </div>
                            <img [src]="empresa.logo" alt="" class="align-self-end h-100px">
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Stats Widget 4-->
                </div>
            </div>
        </div>
    </div>
