<div style="float:right;">
	<!--<a [href]="url + 'descargar_csv?t=' + table + '&name=' + moduleName + '&empresa=' + req.loggedUser.empresa_selected.id"
							 style="width:125px;" class="btn btn-success">
	<i class="la la-download"></i>
	Descargar
	</a>-->
	<button *ngIf="canDownload" class="btn  btn-primary font-weight-bold px-4 ml-2"
		data-toggle="modal" data-target="#descargarCSV">
		<i class="flaticon-download-1"></i>Descargar
	</button>
	<button *ngIf="canImport" class="btn  btn-primary font-weight-bold px-4 ml-2"
		data-toggle="modal" data-target="#seleccionarCSV">
		<i class="la la-upload"></i>Importar CSV
	</button>
	<div class="modal fade" id="seleccionarCSV" data-backdrop="static" tabindex="-1" role="dialog"
					aria-hidden="true" style="margin-top:100px;">
		<div class="modal-dialog" role="document" >
			<div class="modal-content">
				<div class="modal-header btn-danger">
					<h3>Seleccionar CSV</h3>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<i aria-hidden="true" class="ki ki-close"></i>
					</button>
				</div>
				<div>
					<div class="row" style="padding: 5px">
						<div class="col-lg-12" style="margin-top:5px;">
							<a [href]="url + 'csv_plantilla?t=' + table + '&name=' + moduleName"
								class="btn btn-success">
							<i class="la la-download"></i>
							Descargar Plantilla
							</a>
						</div>

						<div class="col-lg-12" style="margin-top:5px;">
							<label>Seleccione archivo CSV:</label><br/>
							<input (change)="changeCSV($event)" name="csv" type="file" accept=".csv" />
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" (click)="importarPlantilla()" class="btn btn-success font-weight-bold" >Importar</button>
					<button type="button" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
	<div  class="modal fade" id="descargarCSV" data-backdrop="static" tabindex="-1" role="dialog"
				aria-labelledby="staticBackdrop" aria-hidden="true" style="margin-top:100px;">
		<div class="modal-dialog" role="document" >
			<div class="modal-content">
				<div class="modal-header btn-danger">
					<h1>Descargar {{moduleName}}</h1>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<i aria-hidden="true" class="ki ki-close"></i>
					</button>
				</div>
				<div>
					<div class="row" style="padding: 5px">
						<div class="col-lg-6" style="margin-top:5px;">
							<label>Fecha Inicial:</label>
							<input [(ngModel)]="fecha_inicial"
										 name="fecha_inicial" required
										 class="form-control" type="date"/>
						</div>
						<div class="col-lg-6" style="margin-top:5px;">
							<label>Fecha Final:</label>
							<input [(ngModel)]="fecha_final"
										 name="fecha_final" required
										 class="form-control" type="date"/>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<a *ngIf="table != 'encuestas'" [href]="url + 'descargar_csv?t=' + table + '&name=' + moduleName + '&empresa=' + req.loggedUser.empresa_selected.id + '&fecha_inicial=' + fecha_inicial + '&fecha_final=' + fecha_final"
						 style="width:125px;" class="btn btn-success">
						<i class="la la-download"></i>
						Descargar
					</a>
					<a *ngIf="table == 'encuestas'" [href]="url + 'csv_encuesta?id=' + id + '&empresa_id=' + req.loggedUser.empresa_selected.id + '&fecha_inicial=' + fecha_inicial + '&fecha_final=' + fecha_final"
					 style="width:125px;" class="btn btn-success">
						<i class="la la-download"></i>
						Descargar
					</a>
					<button type="button" style="width: 125px;" class="btn btn-danger font-weight-bold" data-dismiss="modal">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</div>
