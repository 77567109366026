import {Component, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import * as moment from 'moment';
import {Nav} from '../../../services/nav.service';
import {RequestService} from '../../../services/request.service';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	ranges: any = {
		'Hoy': [moment(), moment()],
		'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Ultimos 7 días': [moment().subtract(6, 'days'), moment()],
		'Ultimos 30 días': [moment().subtract(29, 'days'), moment()],
		'Este mes': [moment().startOf('month'), moment().endOf('month')],
		'Mes anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	};
	locale:any;

	indicadores: any = {total: 0, inventario: 0};

	options: any;
	@ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  	@ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
	markerPositions: any = [];
	markerOptions: google.maps.MarkerOptions = {draggable: false};
	tienePermiso: boolean = false;
	fecha_inicial: any;
	fecha_final:any;
	loadingSearchMap:boolean;

	usuarios = [];
	loadingUsers: boolean = true;

	constructor(public req: RequestService, public nav: Nav) { }

	async ngOnInit() {
		moment.locale('es');
		this.locale = {
			format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
			displayFormat: 'MM/DD/YYYY', // default is format value
			direction: 'ltr', // could be rtl
			weekLabel: 'S',
			separator: ' a ', // default is ' - '
			cancelLabel: 'Cancelar', // detault is 'Cancel'
			applyLabel: 'Aplicar', // detault is 'Apply'
			clearLabel: 'Limpiar', // detault is 'Clear'
			customRangeLabel: 'Rango Personalizado',
			daysOfWeek: moment.weekdaysMin(),
			monthNames: moment.monthsShort(),
			firstDay: 1 // first day is monday
		};
		var start = moment().subtract(30, 'days');
		var end = moment();

		this.indicadores = await this.req.GET('gastos_pendientes?empresa=' + this.req.loggedUser.empresa_selected.id);
	}

	async searchUsuarios(){
		const url = 'usuarios?estado=Activo&empresa=' + this.req.loggedUser.empresa_selected.id;
		return await this.req.GET(url);;
	}
}
