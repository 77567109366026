import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Departamento, Menu, PosicionesEmpleados, Usuario} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  datas:any;
  dtTrigger= new Subject();
  modulo = "usuarios";
  departamentos: Departamento[];
  supervisores: Usuario[];
  currentMenu: Menu;
  search:Usuario = new Usuario();
  constructor(public req: RequestService, private ngZone: NgZone, private nav: Nav, private router: Router) { }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  async ngOnInit(){
    this.search.estado    = '';
    this.search.departamento    = '';
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      lengthChange: false
    };

    setTimeout(()=>{
      this.currentMenu    = this.req.getMenu('usuarios');
    },200)
    this.datas = await this.req.GET('usuarios?empresa='+this.req.loggedUser.empresa_selected.id);
    this.dtTrigger.next();
  }

  edit(id: number) {
    this.nav.push("usuarios/add-or-edit",{id});
  }
  buscar(){
    this.datatableElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
      this.datas = null;
      dtInstance.destroy();
      this.datas = await this.req.GET('usuarios?empresa='+ this.req.loggedUser.empresa_selected.id +'nombres=' + this.search.nombres + "&apellidos=" + this.search.apellidos + "&estado=" + this.search.estado + "&departamento=" + this.search.departamento + "&email=" + this.search.email);
      this.dtTrigger.next();
    });
  }
  empresas(user){
    return user.empresas.map(p=> p.nombre).join(', ');
  }
}
