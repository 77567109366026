import { Component, OnInit } from '@angular/core';
import {LoteriaCombinacion, Resultado, TipoJuego} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
import { environment } from 'src/environments/environment.prod';
import { FormControl, FormGroup } from '@angular/forms';
declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
declare var $ : any;
@Component({
  selector: 'app-resultados-add-or-edit',
  templateUrl: './resultados-add-or-edit.component.html',
  styleUrls: ['./resultados-add-or-edit.component.css']
})
export class ResultadosAddOrEditComponent implements OnInit {
  modulo = 'resultados';
  table   = 'resultados'
  img_default = environment.default_avatar_boy;
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  resultado:Resultado = new Resultado();
  // loteria_combinacion: LoteriaCombinacion = new LoteriaCombinacion();
  ActionType: ActionType;
  is_dir_or_admin :boolean;
  loadingResetPass:boolean;
  sorteos:any;
  loterias:any;
  loteria_id:any;
  sorteo_id:any;
  tipo_juegos:any;
  selecteds:any = [];
  combinaciones_exist:boolean = false;
  combinaciones:any = [];
  combinations_value:any = [];
  values: any = [];
  current:any = [];
  cantidad_posiciones = 0;


  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {
    this.actRoute.queryParamMap.subscribe(async params => {
      this.resultado.id = parseInt(params.get('id'));


      this.loterias = await this.req.POST('data', {table:'loterias'})??[];
      
      if(this.resultado.id){
        const response = await this.req.GET('resultados?module=' + this.table + '&id=' + this.resultado.id);
        this.selecteds = response.response.tipo_juego;
        this.resultado = response.response;
        this.tipo_juegos = this.selecteds.map(p=>p.id);
        this.accion = ActionType.EDIT;  

        // Cargar combinaciones
        if (this.selecteds.length > 0) {
          await this.loadCombinations();
          // Cargar valor de combinaciones
          // await this.loadCombinacionesValue();
        }

        
      }
      if(ActionType.CREATE === this.accion) {
        
      }
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 200);
      this.loading  = false;
      this.validation = FormValidation.formValidation(
        KTUtil.getById('kt_loteria_form'),
        {
          fields: {
            nombre: {
              validators: {
                notEmpty: {
                  message: 'Nombre es requerida.'
                }
              }
            },
            estado: {
              validators: {
                notEmpty: {
                  message: 'Estado es requerido.'
                }
              }
            },
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
            bootstrap: new FormValidation.plugins.Bootstrap()
          }
        }
      );
    });
  }
   

  async guardar(){
    const status = await this.validation.validate();
    if (this.combinations_value.length < this.cantidad_posiciones) {
      Swal.fire(
        'Campos incompletos',
        'Debes completar todos los campos',
        'error'
      );
      return;
    }
    var data = {
      "loteria_id":this.loteria_id,
      "sorteo_id":this.sorteo_id,
      "resultados":this.combinations_value
    };

    if (ValidForm.VALID === status){
      // this.loadingSave  = true;
      const response    = await this.req.POST('save_resultado', data);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        
        Swal.fire(
          '',
          '<p>Se ha ' + accion + ' satisfactoriamente un ' + this.modulo + '.</p>',
          'success'
        );

        this.nav.push('resultados/'+response.response.id+'/ganadores');
      }
    }
  }

  async loadCombinations(){
    for (let i = 0; i < this.selecteds.length; i++) {
      var element = this.selecteds[i];
      const res = await this.req.GET('tipo_juego?module=' + this.table + '&id=' + element.id);
      
      this.combinaciones.push({"combinaciones":res.response});

    }
    
    if (this.combinaciones) {
      this.combinaciones_exist = true;
    }

  }

  loaded = false;
  changeImage(){
    if(!this.loaded){
      this.loaded = true;
      let image = new KTImageInput('kt_image_logo');
      console.log(image)
      //img_default = environment.default_avatar_boy;
      image.on('change', async (data) => {
        console.log(data)
        $('input[name=image_logo]').value = String(await this.req.getDataUrlFromFile(data.input.files[0])).replace('data:image/png', 'data:image/jpeg');
        // this.loteria.logo =  String(await this.req.getDataUrlFromFile(data.input.files[0])).replace('data:image/png', 'data:image/jpeg');
      });
    }
  }
 

  findCombinations (high, total, low = 1) {
    if (total === 0) {
      this.combinations_value.push([...this.current])
      return this.combinations_value
    }
    for (let i = low; i <= high; i++) {
      this.current.push(i)
      this.findCombinations(high, total - 1, i + 1)
      this.current.pop()
    }
    return this.combinations_value
  }

  valueChange(event, x){

    var valor = this.values[x];

    if(this.values[x] < 10){
      valor = this.req.addCero(valor, 2, '');
    }

    var posicion = parseInt(event.srcElement.posicion);

    this.combinations_value[x] = {
      posicion_id:posicion,
      numero:valor
    };
  
  }

  async loteriaChanged(event){
     // Obtener las posiciones
     var data = {
      "loteria_id":event,
     }
    var response = await this.req.POST('sorteos_loteria',data);

    if(response.responseCode === "success"){
      this.sorteos = response.response
    }

    this.loteria_id = event;
    // console.log(this.sorteos)
  }

  async sorteoChanged(event){
    this.combinaciones = [];
    this.combinaciones_exist = false;


    const res = await this.req.GET('resultado?id='+event);

    if(res.responseCode != 'success'){
      Swal.fire(
        res.message,
        '',
        'error'
      );
      return;
    }

    var data = {
      'id':event
    }
    var response = await this.req.POST('sorteo', data);
    var res_posiciones = await this.req.GET('posiciones');
    var posiciones;
    if(res_posiciones.responseCode == "success"){
      posiciones = res_posiciones.response
    }

    if (response.responseCode == "success") {
      var cantidad_posiciones = response.response.cantidad_posiciones;

      for (let x = 0; x < cantidad_posiciones; x++) {
       this.combinaciones.push(posiciones[x])
      }

      if(this.combinaciones.length > 0){
        this.combinaciones_exist = true;
      }

    }

    this.cantidad_posiciones = cantidad_posiciones;
    this.sorteo_id = event;
  }

}
