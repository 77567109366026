import {Component, NgZone, OnInit} from '@angular/core';
import {RequestService} from '../../../services/request.service';
import {Nav} from '../../../services/nav.service';
import {Router} from '@angular/router';
import Swal from "sweetalert2";

@Component({
  selector: 'app-select-empresa',
  templateUrl: './select-empresa.component.html',
  styleUrls: ['./select-empresa.component.css']
})
export class SelectEmpresaComponent implements OnInit {

  constructor(public req: RequestService, private ngZone: NgZone, private nav: Nav, private router: Router) { }

  ngOnInit(): void {
  }

  selectEmpresa(empresa: any) {
    this.req.loggedUser.empresa_selected = empresa;
    this.req.setLoggedUser(this.req.loggedUser);
    if (this.req.loggedUser.reset_password === 1) {
      this.nav.push('dashboard');
    } else {
      Swal.fire(
          '',
          'Por favor cambie su contraseña.',
          'warning'
      );
      this.nav.push('profile');
    }
  }
}
