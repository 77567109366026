import { Component, OnInit } from '@angular/core';
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
import { NgForm } from '@angular/forms';
import { Cartera } from 'src/interfaces/interfaces';
declare var KTUtil;
declare var FormValidation;
declare var $ : any;

@Component({
  selector: 'app-carteras-add-or-edit',
  templateUrl: './carteras-add-or-edit.component.html',
  styleUrls: ['./carteras-add-or-edit.component.css']
})
export class CarterasAddOrEditComponent implements OnInit {
  modulo = 'cartera';
  table   = 'carteras'
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  ActionType: ActionType;
  is_dir_or_admin :boolean;
  loadingResetPass:boolean;
  selected:any = [];
  cartera: Cartera = new Cartera();
  clientes:any = [];
  cliente:any;
  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {
    this.actRoute.queryParamMap.subscribe(async params => {
      // this.cartera.id = parseInt(params.get('id'));

      // Obtener las posiciones
      const parms = {
        table: 'clientes'
      };
      
      this.clientes = await this.req.POST('data', parms)??[];
      this.cartera.tipo = 1;
      if(ActionType.CREATE === this.accion) {
        
      }
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 200);
      this.loading  = false;
      this.validation = FormValidation.formValidation(
        KTUtil.getById('kt_login_signin_form'),
        {
          fields: {
            nombre: {
              validators: {
                notEmpty: {
                  message: 'Nombre es requerida.'
                }
              }
            },
            cantidad_jugadas: {
              validators: {
                notEmpty: {
                  message: 'Cantidad de jugadas es requerido.'
                }
              }
            },
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
            bootstrap: new FormValidation.plugins.Bootstrap()
          }
        }
      );
    });
  }
  async guardar(){
    
    this.cartera.cliente_id = this.cliente;
    this.cartera.trx = this.req.generateRamdomStringUppercase(10);
    this.cartera.user_id = this.req.loggedUser.id;
    this.cartera.change_amount = true;

    const status = await this.validation.validate(); 

    if (ValidForm.VALID === status){
      this.loadingSave  = true;
      const response    = await this.req.POST('save_cartera', this.cartera);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        this.nav.push('carteras');
        Swal.fire(
          '',
          '<p>Se ha ' + accion + ' satisfactoriamente unc ' + this.modulo + '.</p>',
          'success'
        );


      }
    }
  }


}
