import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {SearchComponent} from './pages/search/search.component';
import {AddOrEditComponent} from './pages/add-or-edit/add-or-edit.component';
import {UsuariosComponent} from './pages/usuarios/usuarios.component';
import {UsuariosAddOrEditComponent} from './pages/usuarios-add-or-edit/usuarios-add-or-edit.component';
import {EditProfileComponent} from './pages/edit-profile/edit-profile.component';
import {SelectEmpresaComponent} from './pages/select-empresa/select-empresa.component';
import { TipoJuegoComponent } from './pages/tipo-juego/tipo-juego.component';
import {TipoJuegoAddOrEditComponent} from './pages/tipo-juego-add-or-edit/tipo-juego-add-or-edit.component';
import {LoteriaComponent} from './pages/loteria/loteria.component';
import {LoteriaAddOrEditComponent} from './pages/loteria-add-or-edit/loteria-add-or-edit.component'
import { ResultadosComponent } from './pages/resultados/resultados.component';
import { ResultadosAddOrEditComponent } from './pages/resultados-add-or-edit/resultados-add-or-edit.component';
import { CarterasComponent } from './pages/carteras/carteras.component';
import { CarterasAddOrEditComponent } from './pages/carteras-add-or-edit/carteras-add-or-edit.component';
import { JugadasComponent } from './pages/jugadas/jugadas.component';
import { ResultadosGanadoresComponent } from './pages/resultados-ganadores/resultados-ganadores.component';
import { SolicitudRetirosComponent } from './pages/solicitud-retiros/solicitud-retiros.component';
import { ClientsComponents } from './pages/clients/clients.component';
import { ClientsAddOrEditComponent } from './pages/clients-add-or-edit/clients-add-or-edit.component';
import { LimitesComponents } from './pages/limites/limites.component';
import { LimitessAddOrEditComponent } from './pages/limites-add-or-edit/limites-add-or-edit.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'add-or-edit',
    component: AddOrEditComponent
  },
  {
    path: 'usuarios',
    component: UsuariosComponent
  },

  {
    path: 'usuarios/add-or-edit',
    component: UsuariosAddOrEditComponent
  },
  {
    path: 'profile',
    component: EditProfileComponent
  },
  {
    path: 'seleccione-empresa',
    component: SelectEmpresaComponent
  },
  {
    path: 'tipo-juego',
    component: TipoJuegoComponent,
  },
  {
    path: 'tipo-juego/add-or-edit',
    component: TipoJuegoAddOrEditComponent
  },
  {
    path: 'loteria',
    component: LoteriaComponent
  },
  {
    path:'loteria/add-or-edit',
    component: LoteriaAddOrEditComponent
  },
  {
    path:'resultados',
    component: ResultadosComponent
  },
  {
    path:'resultados/:id/ganadores',
    component: ResultadosGanadoresComponent
  },
  {
    path:'resultados/add-or-edit',
    component: ResultadosAddOrEditComponent
  },
  {
    path:'carteras',
    component: CarterasComponent
  },
  {
    path:'carteras/add-or-edit',
    component: CarterasAddOrEditComponent
  },
  {
    path:'jugadas',
    component: JugadasComponent
  },
  {
    path:'solicitud-retiros',
    component: SolicitudRetirosComponent
  },
  {
    path:'clients',
    component: ClientsComponents
  },
  {
    path:'clients/add-or-edit',
    component:ClientsAddOrEditComponent
  },
  {
    path:'limites',
    component:LimitesComponents
  },
  {
    path:'limites/add-or-edit',
    component:LimitessAddOrEditComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
