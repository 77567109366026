import { Component, OnInit } from '@angular/core';
import {Departamento, PosicionesEmpleados, Usuario} from "../../../interfaces/interfaces";
import {RequestService} from "../../../services/request.service";
import {Nav} from "../../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionType, GenderType, RoleType, ValidForm} from "../../../enums/enums";
import Swal from "sweetalert2";
import {environment} from "../../../environments/environment.prod";
declare var KTUtil;
declare var FormValidation;
declare var KTImageInput;
declare var KTDialog;
declare var $ : any;
@Component({
  selector: 'app-usuarios-add-or-edit',
  templateUrl: './usuarios-add-or-edit.component.html',
  styleUrls: ['./usuarios-add-or-edit.component.css']
})
export class UsuariosAddOrEditComponent implements OnInit {
  modulo = 'usuario';
  table   = 'users'
  accion = ActionType.CREATE;
  loading:boolean = true;
  validation:any;
  loadingSave:boolean;
  departamento:Usuario = new Usuario();
  ActionType: ActionType;
  RoleType = RoleType;
  departamentos: Departamento[];
  roles:any;
  avatar:any;
  image:any;
  img_default:string;
  is_dir_or_admin :boolean;
  loadingResetPass:boolean;
  single_empresa = environment.single_business;
  empresas:any;
  constructor(public req: RequestService,public nav: Nav, private router: Router, private actRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
  async ngOnInit() {
    this.avatar = new KTImageInput('kt_image_2');
    this.img_default = environment.default_avatar_boy;
    this.avatar.on('change', async (data)=>{
      console.log(data.input.files);
      this.departamento.avatar = await this.req.getDataUrlFromFile(data.input.files[0])
    });
    this.actRoute.queryParamMap.subscribe(async params => {
      this.departamento.id = parseInt(params.get('id'));
      if(this.departamento.id){
        const response = await this.req.GET('module_data?module=' + this.table + '&id=' + this.departamento.id);
        this.departamento = response.response;
        this.accion = ActionType.EDIT;
      }
      if(ActionType.CREATE === this.accion) {
        this.departamento.estado = 'Activo';
        this.departamento.genero = 'Masculino';
        this.departamento.departamento = '';
        this.departamento.role_id = '';
      }
      this.roles = await this.req.POST('roles',{});
      this.empresas = await this.req.POST('data', {table: 'empresas', estado : 'Activo'});
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 200);
      this.loading  = false;
      this.validation = FormValidation.formValidation(
        KTUtil.getById('kt_login_signin_form'),
        {
          fields: {
            empresas: {
              validators: {
                notEmpty: {
                  message: 'Empresa es requerida.'
                }
              }
            },
            nombres: {
              validators: {
                notEmpty: {
                  message: 'Nombre es requerido.'
                }
              }
            },
            apellidos: {
              validators: {
                notEmpty: {
                  message: 'Apellido es requerido.'
                }
              }
            },
            usuario: {
              validators: {
                notEmpty: {
                  message: 'Usuario es requerido.'
                }
              }
            },
            email: {
              validators: {
                notEmpty: {
                  message: 'Email es requerido.'
                }
              }
            },
            estado: {
              validators: {
                notEmpty: {
                  message: 'Estado es requerido.'
                }
              }
            }
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            //defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
            bootstrap: new FormValidation.plugins.Bootstrap()
          }
        }
      );
    });
  }
  async guardar(){
    const status = await this.validation.validate();
    if (ValidForm.VALID === status){
      this.loadingSave  = true;
      if (this.single_empresa) {
        this.departamento.empresa = [1];
      }
      const response    = await this.req.POST('guardar_usuario', this.departamento);
      this.loadingSave  = false;
      if (response.responseCode === 'error') {
        Swal.fire(
          '',
          response.message,
          'error'
        );
        return;
      }else{
        const accion = ActionType.CREATE === this.accion ? 'guardado' : 'actualizado';
        this.nav.push('usuarios');
        Swal.fire(
          '',
          '<p>Se ha ' + accion + ' satisfactoriamente una ' + this.modulo + '.</p>',
          'success'
        );
      }
    }
  }

  async validateExist(field, value) {
    const response = await this.req.GET('validate_exist?module=' + this.table + '&field=' + field + '&value=' + value + '&id=' + this.departamento.id);
    if (response.responseCode === 'error') {
      Swal.fire(
        '',
        response.message,
        'error'
      );
      if(field === 'email') {
        this.departamento.email = null;
      }else if(field === 'usuario'){
        this.departamento.usuario = null;
      }
      await this.validation.validate();
      return;
    }
  }

  handleChange() {
    if(GenderType.FEMENINE === this.departamento.genero){
      this.img_default = environment.default_avatar_girl;
    }else{
      this.img_default = environment.default_avatar_boy;
    }
  }

  changeRole() {
    console.log(this.departamento);
    if(this.departamento.role_id === RoleType.DIRECTOR || this.departamento.role_id === RoleType.ADMIN){
      this.is_dir_or_admin = true;
      this.departamento.departamento = '';
    }else{
      this.is_dir_or_admin = false;
    }
  }

  async resetPassword() {
    this.loadingResetPass = true;
    await this.req.POST('reset_user_password',{id: this.departamento.id})
    this.loadingResetPass = false;
    Swal.fire(
      '',
      '<p>Se ha reiniciado la contraseña satisfactoriamente.</p>',
      'success'
    );
  }
}
